import { createContext, Dispatch } from 'react';
import { HEADER_TAB_KEY } from '@/Constants';

export const initialState: State = {
  // headerTabKey: HEADER_TAB_KEY.CHARGE_DATA,
  width: 0,
  isSetScreenSize: false,
  // stationId: '_global', // 全局数据
  needRefreshFor0100: false,
}

export const StateContext = createContext<State | null>(null);
export const DispatchContext = createContext<Dispatch<Action> | null>(null);

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setTabKey': {
      const { payload } = action;
      return {
        ...state, 
        headerTabKey: payload,
      }
    }; break;
    case 'setScreenSize': {
      const { payload } = action;
      return {
        ...state, 
        ...payload,
        isSetScreenSize: true,
      };
    }; break;
    case 'setStationId': {
      const { payload } = action;
      return {
        ...state, 
        ...payload,
      };
    }; break;
    case 'setMidData': {
      const { payload } = action;
      return {
        ...state, 
        midData: payload,
      };
    }; break;
    case 'setDeviceStatusPercent': {
      const { payload } = action;
      return {
        ...state,
        deviceStatusPercent: payload,
      }
    }; break;
    case 'setNeedRefresh': {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      }
    }; break;
    default: return state;
  }
}
