import { StateContext } from "@/StateContext";
import BigAlert from "@/components/BigAlert";
import { useContext, useEffect, useState } from "react";

type Data = Array<{
  start: number;
  end: number;
}>

function StatisticsData() {
  const [data, setData] = useState<Data>([
    {start: 0, end: 0},
    {start: 0, end: 0},
    {start: 0, end: 0},
  ]);
  const state = useContext(StateContext);

  useEffect(() => {
    if (state?.midData) {
      setData([
        {start: data[0].end, end: state.midData.realChargePower},
        {start: data[1].end, end: state.midData.realChargeNum},
        {start: data[2].end, end: state.midData.totalChargePower},
      ]);
    }
  }, [state?.midData]);

  return (
    <div className='midValue' style={{marginTop: 40..toScale2()}}>
      <BigAlert label='今日实时充电量(度)' start={data[0].start || 0} end={data[0].end || 0} />
      <BigAlert label='今日实时充电次数(次)' start={data[1].start || 0} end={data[1].end || 0} />
      <BigAlert label='历史累计充电量(度)' start={data[2].start || 0} end={data[2].end || 0} />
    </div>
  )
}

export default StatisticsData;
