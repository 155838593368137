interface AccessProps extends React.PropsWithChildren {
  accessible: boolean;
}

/**
 * 使用绝对定位和 zIndex 属性来控制元素的可见性
 * 这样做是为了能够将所有元素都加载到 DOM 树中
 * 保证资源不被垃圾回收机制回收
 * 保证后续交互的流畅性，避免重新加载资源造成卡顿
 * @param props 
 * @returns 
 */
const Access: React.FC<AccessProps> = (props) => {
  return (
    <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: props.accessible ? 1 : -1
    }}>
      {props.children}
    </div>
  )
}

export default Access;
