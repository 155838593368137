import Label from "@/components/Label";
import { useContext, useEffect, useState } from "react";
import * as echarts from 'echarts';
import { useRequest } from "ahooks";
import { generateYAxis, getMaxValue } from "@/utils";
import { FAKE_DATA, SECONDS_PER_FRAME, SUCCESS_CODE } from "@/Constants";
import request from "@/request";
import { StateContext } from "@/StateContext";
import Card from "@/components/Card";

type Data = Array<{
  /**
   * 充电次数
   */
  num: number;
  /**
   * 日期
   */
  title: string;
}>

async function fakeData() {
  return Promise.resolve({
    ...FAKE_DATA,
    data: new Array(30).fill(1).map((_,i) => ({num: Math.floor(Math.random() * 1000 + 100), title: `${i}`}))
  })
}

async function requestData(stationId?: string): Promise<Result<Data>> {
  return fakeData();
  return request.run('/bigV1/thirtyChargeNum', {
    method: 'GET', 
    params: {
      stationId
    }
  })
}

function draw(chart: echarts.ECharts, data: Data) {
  const y = data.map(it => it.num);
  const maxValue = getMaxValue(y);

  const x = new Array(30).fill(1).map((_, i) => `${i + 1}`);

  const option = {
    xAxis: {
      type: 'category',
      boundaryGap: [20..toScale(), 20..toScale()],
      data: x,
      axisTick: {
        show: false,
      },
      axisLabel: {
        interval: (i: number) => i === 0 || i % 5 === 4,
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: 'rgba(255,255,255,0.1)',
        }
      },
      axisLabel: {
        show: false,
      },
      max: maxValue, 
      interval: maxValue / 4,
    },
    barCategoryGap: 4..toScale(),
    grid: {
      top: 10..toScale2(),
      left: 40..toScale(), 
      right: 20..toScale(),
      bottom: 20..toScale2(),
    },
    series: [
      {
        type: 'bar',
        data: y,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(0, 128, 254, 1)' },
            { offset: 1, color: 'rgba(0, 128, 254, 0)' }
          ]),
        },
      },
      {
        type: 'custom',
        data: y,
        renderItem: function(_: any, api: any) {
          const topAxis = api.coord([api.value(0), api.value(1)]);
          var currentSeriesIndices = api.currentSeriesIndices();
          var barLayout = api.barLayout({
            barGap: '0%',
            barCategoryGap: `${4..toScale()}`,
            count: currentSeriesIndices.length - 1
          });
          const offset = barLayout[0].offset;
          return {
            type: 'path',
            style: {
              stroke: '#2c97ff',
              lineWidth: 2,
            },
            shape: {
              d: `M${topAxis[0]+offset} ${topAxis[1]} L${topAxis[0]-offset} ${topAxis[1]}`
            },
            enterFrom: {
                style: { opacity: 0 },
                y: 100..toScale() - topAxis[1]
            }
          }
        },
      }
    ]
  };
  chart.setOption(option);
  generateYAxis(chart, maxValue);
}

function ChargeCount() {
  const state = useContext(StateContext);
  const [chart, setChart] = useState<echarts.ECharts>();
  const { data, run } = useRequest(() => requestData(state?.stationId), {
    ready: !!chart && !!state?.stationId,
    refreshDeps: [state?.stationId, state?.headerTabKey],
    onSuccess: (res) => {
      if (res.code === SUCCESS_CODE) {
        draw(chart!, res.data);
      }
    }
  })

  useEffect(() => {
    if (state?.needRefreshFor0100) {
      run();
    }
  }, [state?.needRefreshFor0100]);

  useEffect(() => {
    if (chart) return;
    // 0 为默认值，等于 0 意味着还未获取到当前的屏幕宽度
    if (state?.width === 0) return;
    const chartDom = document.getElementById('chargeCountChart');
    if (!chartDom) return;
    setChart(echarts.init(chartDom));
  }, [state?.width]);

  return (
    <Card title='近30天充电次数' style={{marginTop: 68..toScale()}}>
      <div className='chargeCount'>
        <div className="quantity">
          <div 
            className="quantity-bg" 
            id="chargeCount-quantity"
            style={{backgroundImage: `url(/img/seconds.png)`}}
          />
          <span className='quantity-value'>{data?.data.reduce((a, b) => a + b.num, 0).toLocaleString() || 0}</span>
          <span className='quantity-label'>近30天累计</span>
        </div>
        <div className="userCount-right">
          <div className='label-row'>
            <Label>
              <span>单位: 次</span>
            </Label>
          </div>
          <div id='chargeCountChart'></div>
        </div>
      </div>
    </Card>
  )
}

export default ChargeCount;
