import { SECONDS_PER_FRAME } from "@/Constants";
import { StateContext } from "@/StateContext";
import { useContext, useEffect } from "react";

interface MapProps {
  type: string;
}

function Map(props: MapProps) {
  const state = useContext(StateContext);
  
  return (
    <div className="map" style={{display: state?.headerTabKey === props.type ? 'block' : 'none'}}>
      <img src="/img/map-bg.png" alt="" className='map-bg' />
      <div 
        className="position-icon" 
        style={{
          backgroundImage: 'url(/img/position-icon.png)',
          top: 107 * window.scale, left: 39 * window.scale
        }}
      />
      <div 
        className="position-icon" 
        style={{
          backgroundImage: 'url(/img/position-icon.png)',
          top: 150 * window.scale, left: 156 * window.scale
        }}
      />
      <div 
        className="position-icon" 
        style={{
          backgroundImage: 'url(/img/position-icon.png)',
          top: 165 * window.scale, left: 295 * window.scale
        }}
      />
      <div 
        className="position-icon" 
        style={{
          backgroundImage: 'url(/img/position-icon.png)',
          top: 146 * window.scale, left: 392 * window.scale
        }}
      />
      <div 
        className="position-icon" 
        style={{
          backgroundImage: 'url(/img/position-icon.png)',
          top: 130 * window.scale, left: 418 * window.scale
        }}
      />
      <div 
        className="position-icon" 
        style={{
          backgroundImage: 'url(/img/position-icon.png)',
          top: 104 * window.scale, left: 497 * window.scale
        }}
      />
      <div 
        className="position-icon" 
        style={{
          backgroundImage: 'url(/img/position-icon.png)',
          top: 120 * window.scale, left: 541 * window.scale
        }}
      />
      <div 
        className="position-icon" 
        style={{
          backgroundImage: 'url(/img/position-icon.png)',
          top: 120 * window.scale, left: 571 * window.scale
        }}
      />
      <div 
        className="position-icon" 
        style={{
          backgroundImage: 'url(/img/position-icon.png)',
          top: 107 * window.scale, left: 650 * window.scale
        }}
      />
      <div 
        className="position-icon" 
        style={{
          backgroundImage: 'url(/img/position-icon.png)',
          top: -3 * window.scale, left: 685 * window.scale
        }}
      />
    </div>
  )
}

export default Map;
