import { FAKE_DATA } from "@/Constants";
import { StateContext } from "@/StateContext";
import Card from "@/components/Card";
import request from "@/request";
import { useRequest } from "ahooks";
import { useContext, useEffect } from "react";

interface Data {
  stationNum: number;
  stubNum: number;
}

async function fakeData() {
  return Promise.resolve({
    ...FAKE_DATA,
    data: {
      stationNum: 156,
      stubNum: 1862,
    }
  })
}

async function requestData(stationId?: string): Promise<Result<Data>> {
  return fakeData();
  return request.run('/bigV1/countNum', {
    method: 'GET', 
    params: {
      stationId
    }
  })
}

function DeviceData() {
  const state = useContext(StateContext);
  const { data, run } = useRequest(() => requestData(state?.stationId), {
    ready: !!state?.stationId,
    refreshDeps: [state?.stationId, state?.headerTabKey],
  })

  useEffect(() => {
    if (state?.needRefreshFor0100) {
      run();
    }
  }, [state?.needRefreshFor0100]);

  return (
    <Card title='设备数据' style={{marginTop: 20..toScale2()}}>
      <div className='deviceData'>
        <div className="box">
          <img alt='' src='/img/thunder3.png' />
          <div className="content">
            <div className="title">充电站总数</div>
            <div className="value">{data?.data?.stationNum.toLocaleString() || 0}</div>
          </div>
        </div>
        <div className="box">
          <img alt='' src='/img/thunder2.png' />
          <div className="content">
            <div className="title">充电设备总数</div>
            <div className="value">{data?.data?.stubNum.toLocaleString() || 0}</div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default DeviceData;
