import { FAKE_DATA, SUCCESS_CODE } from "@/Constants";
import { StateContext } from "@/StateContext";
import Card from "@/components/Card";
import Label from "@/components/Label";
import request from "@/request";
import { useRequest } from "ahooks";
import { last } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import * as echarts from 'echarts';
import { getMaxValue } from "@/utils";

interface Data {
  /**
   * 月份
   */
  month: string;
  /**
   * 数量
   */
  num: number;
  /**
   * 额定功率
   */
  rate: number;
}

async function fakeData() {
  return Promise.resolve({
    ...FAKE_DATA,
    data: [
      {month: '1月', num: 52, rate: 3512},
      {month: '2月', num: 57, rate: 3832},
      {month: '3月', num: 48, rate: 3369},
      {month: '4月', num: 68, rate: 4063},
      {month: '5月', num: 66, rate: 3987},
      {month: '6月', num: 89, rate: 5762},
    ]
  })
}

async function requestData(stationId?: string): Promise<Result<Array<Data>>> {
  return fakeData();
  return request.run('/bigV1/sixMonthData', {
    method: 'GET', 
    params: {
      stationId
    }
  })
}

function draw(chart: echarts.ECharts, data: Array<Data>) {
  const x: string[] = []
  const y1: number[] = []
  const y2: number[] = []
  data.forEach(it => {
    x.push(it.month);
    y1.push(it.num);
    y2.push(it.rate);
  })
  const maxValue1 = getMaxValue(y1);
  const maxValue2 = getMaxValue(y2);
  const formatterY1: any = [];
  const formatterY2: any = [];
  const max = Math.max(...y1);
  y1.forEach(it => {
    if (it === max) {
      formatterY1.push({
        value: it,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(120, 255, 183, 0.50)' },
            { offset: 1, color: 'rgba(120, 255, 183, 0)' }
          ]),
        }
      })
      formatterY2.push({
        value: it,
        itemStyle: {
          color: 'rgba(120, 255, 183, 1)'
        }
      })
    } else {
      formatterY1.push(it);
      formatterY2.push(it);
    }
  })
  const option = {
    xAxis: [
      {
        type: 'category',
        data: x,
        axisTick: {
          show: false,
        },
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        max: maxValue1,
        interval: maxValue1 / 4,
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: 'rgba(255,255,255,0.1)',
          }
        },
      },
      {
        type: 'value',
        max: maxValue2,
        interval: maxValue2 / 4,
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: 'rgba(255,255,255,0.1)',
          }
        },
      }
    ],
    grid: {
      top: 20..toScale2(),
      left: 40..toScale(), 
      right: 40..toScale(),
      bottom: 20..toScale2(),
    },
    barCategoryGap: 16..toScale(),
    series: [
      {
        name: 'Evaporation',
        type: 'bar',
        markPoint: {
          data: [{name: 'Max', type: 'max'}],
          symbolSize: 0,
          symbolOffset: [0, -13..toScale()],
          label: {
            color: 'rgba(216, 240, 255, 1)',
            fontSize: 13..toScale(),
            fontWeight: 'bold',
          }
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(21, 154, 255, 0.50)' },
            { offset: 1, color: 'rgba(0, 58, 255, 0)' }
          ]),
        },
        data: formatterY1
      },
      {
        type: 'custom',
        data: formatterY2,
        itemStyle: {
          color: 'rgba(45, 155, 252, 1)',
        },
        renderItem: function(_: any, api: any) {
          const style = api.style();
          const topAxis = api.coord([api.value(0), api.value(1)]);
          var currentSeriesIndices = api.currentSeriesIndices();
          var barLayout = api.barLayout({
            barGap: '0%',
            barCategoryGap: `${16..toScale()}`,
            count: currentSeriesIndices.length - 1
          });
          const offset = barLayout[0].offset;
          return {
            type: 'path',
            style: {
              stroke: style.fill,
              lineWidth: 2,
            },
            shape: {
              d: `M${topAxis[0]+offset} ${topAxis[1]} L${topAxis[0]-offset} ${topAxis[1]}`
            },
            enterFrom: {
                style: { opacity: 0 },
                y: 210..toScale() - topAxis[1]
            }
          }
        },
      },
      {
        name: 'Temperature',
        type: 'line',
        symbol: 'circle',
        symbolSize: 8..toScale(),
        yAxisIndex: 1,
        itemStyle: {
          color: 'rgba(120, 255, 183, 1)',
        },
        markPoint: {
          data: [{name: 'Min', type: 'min'}],
          symbolSize: 0,
          symbolOffset: [0, -13..toScale()],
          label: {
            color: 'rgba(216, 240, 255, 1)',
            fontSize: 13..toScale(),
            fontWeight: 'bold',
          }
        },
        data: y2
      }
    ]
  };
  chart.setOption(option);
}

function DeviceAndPowerChange() {
  const state = useContext(StateContext);
  const [chart, setChart] = useState<echarts.ECharts>();
  const { data, run } = useRequest(() => requestData(state?.stationId), {
    ready: !!chart && !!state?.stationId,
    refreshDeps: [state?.stationId, state?.headerTabKey],
    onSuccess: (res) => {
      if (res.code === SUCCESS_CODE) {
        draw(chart!, res.data);
      }
    }
  })

  const currentMonth = useMemo(() => {
    const result = {
      deviceCount: 0,
      power: 0
    }
    if (data?.code === SUCCESS_CODE) {
      const d = last(data.data);
      result.deviceCount = d?.num || 0;
      result.power = d?.rate || 0;
    }
    return result;
  }, [data])

  const dotStyle = {
    width: 6..toScale(),
    height: 6..toScale(),
    borderRadius: '50%',
  }

  useEffect(() => {
    if (state?.needRefreshFor0100) {
      run();
    }
  }, [state?.needRefreshFor0100]);

  useEffect(() => {
    // 0 为默认值，等于 0 意味着还未获取到当前的屏幕宽度
    if (state?.width === 0 || !!chart) return;
    const chartDom = document.getElementById('deviceAndPowerChart');
    setChart(echarts.init(chartDom));
  }, [state?.width]);
  
  return (
    <Card title='近6个月设备与充电功率变化' style={{marginTop: 94..toScale()}}>
      <div className="powerChange">
        <div className="devices-and-power">
          <div className="item">
            <img alt='' src='/img/thunder.png' />
            <div className="content">
              <div className="title">本月设备</div>
              <div className="value">
                <span>{currentMonth.deviceCount.toLocaleString()}</span>
                <span className="suffix"> 个</span>
              </div>
            </div>
          </div>
          <div className="item">
            <img alt='' src='/img/kw.png' />
            <div className="content">
              <div className="title">本月额定功率</div>
              <div className="value">
                <span>{currentMonth.power.toLocaleString()}</span>
                <span className="suffix"> kW</span>
              </div>
            </div>
          </div>
        </div>
        <div className="labelArea">
          <Label>
            <span>单位: 个</span>
          </Label>
          <div className="mid">
            <Label>
              <div style={{...dotStyle, backgroundColor: '#2D9BFC'}}></div>
              <span>设备数量</span>
            </Label>
            <Label>
              <div style={{...dotStyle, backgroundColor: '#78FFB7'}}></div>
              <span>额定功率</span>
            </Label>
          </div>
          <Label>
            <span>单位: kWh</span>
          </Label>
        </div>
        <div id="deviceAndPowerChart"></div>
      </div>
    </Card>
  )
}

export default DeviceAndPowerChange;
