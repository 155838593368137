import { useEffect, useState } from "react";

interface LoadingAssetProps {
  process: number
}

function LoadingAsset(props: LoadingAssetProps) {
  const [items, setItems] = useState(new Array(34).fill(0));

  useEffect(() => {
    setItems(items.map((_, i) => {
      if (i / 34 <= props.process / 100) {
        return 1;
      }
      return 0;
    }))
  }, [props.process]);

  return (
    <div className="loading">
      <span className="loading-text">LOADING...</span>
      <div className="process">
        <div className="left"></div>
        <div className="content">
          {
            items.map((v, i) => {
              return <div key={i} className="item" style={{
                backgroundColor: v ? 'rgba(45, 155, 252, 0.80)' : 'rgba(255, 255, 255, 0.15)',
              }}></div>
            })
          }
        </div>
        <div className="right"></div>
      </div>
      <span className="loading-process">{props.process.toFixed(2)}%</span>
    </div>
  )
}

export default LoadingAsset;
