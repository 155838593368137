import './index.less';

interface LabelProps extends React.PropsWithChildren {
  
}

const Label: React.FC<LabelProps> = (props) => {
  return (
    <div className="label">
      {props.children}
    </div>
  )
}

export default Label;
