const EnergyTab: React.FC = () => {

  return (
    <div className="container">
      <h1>energyTab</h1>
    </div>
  )
}

export default EnergyTab;
