import Label from "@/components/Label";
import { useContext, useEffect, useState } from "react";
import * as echarts from 'echarts';
import { useRequest } from "ahooks";
import { generateYAxis, getMaxValue } from "@/utils";
import { FAKE_DATA, SECONDS_PER_FRAME, SUCCESS_CODE } from "@/Constants";
import Card from "@/components/Card";
import request from "@/request";
import { last } from 'lodash';
import { StateContext } from "@/StateContext";

type Data = Array<{
  /**
   * 注册数量
   */
  num: number;
  /**
   * 月份
   */
  title: string;
}>

async function fakeData() {
  return Promise.resolve({
    ...FAKE_DATA,
    data: new Array(6).fill(1).map((_,i) => ({num: Math.floor(Math.random() * 1000 + 100), title: `${i + 1}月`}))
  })
}

async function requestData(stationId?: string): Promise<Result<Data>> {
  return fakeData();
  return request.run('/bigV1/submitNum', {
    method: 'GET', 
    params: {
      stationId
    }
  })
}

function draw(chart: echarts.ECharts, data: Data) {
  const x = data.map(it => it.title);
  const y = data.map(it => it.num);
  const maxValue = getMaxValue(y);
  const option = {
    xAxis: {
      data: x,
      type: 'category',
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: 'rgba(255,255,255,0.1)',
        },
      },
      axisLabel: {
        show: false,
      },
      max: maxValue, 
      interval: maxValue / 4,
    },
    grid: {
      top: 10..toScale2(),
      left: 40..toScale(), 
      right: 20..toScale(),
      bottom: 20..toScale2(),
    },
    series: [
      {
        name: 'hill',
        type: 'pictorialBar',
        barCategoryGap: '0%',
        symbol: 'path://M0,10 L10,10 C6,10 6,6 5,0 C4,6 4,10 0,10 z',
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(21, 154, 255, 1)' },
            { offset: 1, color: 'rgba(0, 58, 255, 0.10)' }
          ])
        },
        data: y
      },
    ]
  };
  chart.setOption(option);
  generateYAxis(chart!, maxValue);
}

function NewUserCount() {
  const state = useContext(StateContext);
  const [chart, setChart] = useState<echarts.ECharts>();
  const { data, run } = useRequest(() => requestData(state?.stationId), {
    ready: !!chart && state?.stationId === '_global',
    refreshDeps: [state?.stationId, state?.headerTabKey],
    onSuccess: (res) => {
      if (res.code === SUCCESS_CODE) {
        draw(chart!, res.data);
      }
    }
  })

  useEffect(() => {
    if (state?.needRefreshFor0100) {
      run();
    }
  }, [state?.needRefreshFor0100]);

  useEffect(() => {
    // 0 为默认值，等于 0 意味着还未获取到当前的屏幕宽度
    if (state?.width === 0) return;
    if (state?.stationId === '_global') {
      const chartDom = document.getElementById('userCountChart');
      if (!chartDom) return;
      setChart(echarts.init(chartDom));
    } else {
      chart?.dispose();
      setChart(undefined);
    }
  }, [state?.stationId, state?.width]);

  return (
    <Card title='新增注册用户数' style={{marginTop: 20..toScale2()}}>
      {
        state?.stationId === '_global'
        ? (
          <div key='userCount' className='userCount'>
            <div className="quantity">
              <div 
                className="quantity-bg" 
                id="userCount-quantity"
                style={{backgroundImage: `url(/img/seconds.png)`}}
              />
              <span className='quantity-value'>{last((data?.data || []))?.num || 0}</span>
              <span className='quantity-label'>本月新增</span>
            </div>
            <div className="userCount-right">
              <div className='label-row'>
                <Label>
                  <span>单位: 个</span>
                </Label>
              </div>
              <div id='userCountChart'></div>
            </div>
          </div>
        ) : (
          <div key='userCountEmpty' className="userCountEmpty">
            <img alt='' src='/img/empty.png' />
            <span>切换至全局模式查看新增注册用户数据</span>
          </div>
        )
      }
      
    </Card>
  )
}

export default NewUserCount;
