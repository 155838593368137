import { numFormat } from '@/utils';
import './index.less';

interface AlertData {
  cannel: string;
  percent: number;
  total: string;
  suffix?: string;
}

interface AlertProps extends React.PropsWithChildren {
  style?: React.CSSProperties;
  color: string;
  data: AlertData;
}

const Alert: React.FC<AlertProps> = (props) => {
  return (
    <div className='alert' style={{...props.style, '--alert-color': props.color} as any}>
      <div className='category'>
        <span>{props.data.cannel}</span>
        <span>{props.data.percent}%</span>
      </div>
      <div className='value' data-suffix={props.data.suffix || '元'}>
        <span>{numFormat(props.data.total)}</span>
      </div>
    </div>
  )
}

export default Alert;
