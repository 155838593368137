import React, { useEffect, useImperativeHandle, useState } from 'react';
import './index.less';

export interface FlowLightRef {
  rePlay: () => void;
}

const path1 = `
  M 0 1
  L 1800 1
  L 1800 10
`;
const path2 = `
  M 0 88
  L 560 88
  q 45 0 85 -84
  L 1800 6
`;

const sec = 3;
let timeoutId: NodeJS.Timeout | null = null;

const FlowLight = React.forwardRef<FlowLightRef, {}>((props, ref) => {
  const [show, setShow] = useState(true);

  useImperativeHandle(ref, () => ({
    rePlay: () => {
      setShow(false);
      setTimeout(() => setShow(true), 0);
    },
  }))

  useEffect(() => {
    if (!show) return;
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      setShow(false);
    }, 1000 * sec);
  }, [show]);

  return show ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={2160..toScale()}
      height={90..toScale()}
      viewBox='0 0 2160 90'
      className='flowLight'
    >
      <defs>
        <path id='path1' d={path1} fill='transparent' />
        <path id='path2' d={path2} fill='transparent' />

				<radialGradient id="border-gradient" cx="50%" cy="50%" r="50%">
					<stop offset="0%" stopColor="#fff" stopOpacity="1"></stop>
					<stop offset="100%" stopColor="#fff" stopOpacity="0"></stop>
				</radialGradient>

				<mask id="mask1">
					<circle cx="0" cy="0" r="300" fill="url(#border-gradient)">
						<animateMotion dur={`${sec}s`} path={path1} rotate="auto" repeatCount="1"></animateMotion>
					</circle>
				</mask>
				<mask id="mask2">
					<circle cx="0" cy="0" r="300" fill="url(#border-gradient)">
						<animateMotion dur={`${sec}s`} path={path2} rotate="auto" repeatCount="1"></animateMotion>
					</circle>
				</mask>
			</defs>

      {/* <use strokeWidth="2" xlinkHref="#path1" stroke="green"></use> */}
      <use strokeWidth="2" xlinkHref="#path1" mask="url(#mask1)" stroke="#00F0FF">
        <animate attributeName="stroke-dasharray" from="0, 1800" to="1800, 0" dur={`${sec}s`} repeatCount="1"></animate>
      </use>

      {/* <use strokeWidth="2" xlinkHref="#path2" stroke="green"></use> */}
      <use strokeWidth="2" xlinkHref="#path2" mask="url(#mask2)" stroke="#00F0FF">
        <animate attributeName="stroke-dasharray" from="0, 2000" to="2000, 0" dur={`${sec}s`} repeatCount="1"></animate>
      </use>
    </svg>
  ) : null;
})

export default FlowLight;
