import { FAKE_DATA, SUCCESS_CODE } from "@/Constants";
import { DispatchContext, StateContext } from "@/StateContext";
import Card from "@/components/Card";
import TabButtons from "@/components/TabButtons";
import request from "@/request";
import { useRequest } from "ahooks";
import { useContext, useEffect, useMemo, useState } from "react";

enum Type {
  今日 = 1,
  累计 = 2,
}

interface Data {
  /**
   * 私家车占比-私家车占车型的比例
   */
  carTypePri: number;
  /**
   * 车队占比-车队占车型的比例
   */
  carTypeTeam: number;
  /**
   * 运营车占比-运营车占车型的比例
   */
  carTypeWork: number;
  /**
   * 今日实时充电次数(次)
   */
  realChargeNum: number;
  /**
   * 今日实时充电量(度)
   */
  realChargePower: number;
  /**
   * 历史累计充电量(度)
   */
  totalChargePower: number;
  /**
   * 今日充电数据-排名DTO
   */
  todayRanks: SortInfo[];
  /**
   * 累计充电数据-排名DTO
   */
  totalRanks: SortInfo[];
}

async function fakeData() {
  return Promise.resolve({
    ...FAKE_DATA,
    data: {
      carTypePri: Math.random() * 100,
      carTypeTeam: Math.random() * 100,
      carTypeWork: Math.random() * 100,
      realChargeNum: Math.floor(Math.random() * 1000 + 200),
      realChargePower: Math.random() * 10000 + 2000,
      totalChargePower: Math.random() * 100000 + 20000,
      todayRanks: new Array(5).fill(1).map((_,i)=>({rank: i+1,powerNum: parseFloat(((5-i)*10000*Math.random()).toFixed(2)), stationName: `内蒙古${i+1}号`})),
      totalRanks: new Array(5).fill(1).map((_,i)=>({rank: i+1,powerNum: parseFloat(((5-i)*100000*Math.random()).toFixed(2)), stationName: `内蒙古${i+1}号`})),
    }
  })
}

async function requestData(stationId?: string): Promise<Result<Data>> {
  return fakeData();
  return request.run('/bigV1/chargeCenterData', {
    method: 'GET', 
    params: {
      stationId,
      type: 2, // 查询排名和累计数据
    }
  })
}

function SortInfo() {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [type, setType] = useState<Type>(Type.今日);
  useRequest(() => requestData(state?.stationId), {
    ready: !!state?.stationId,
    refreshDeps: [type, state?.stationId, state?.headerTabKey],
    debounceWait: 100,
    onSuccess: (res) => {
      if (res.code === SUCCESS_CODE) {
        // 因为该接口还返回了“三个统计数据、今日排名、累计排名”部分的数据，所以需要将结果存放到state中，供这三个模块使用
        if (!dispatch) return;
        dispatch({
          type: 'setMidData', 
          payload: {
            realChargeNum: res.data.realChargeNum,
            realChargePower: res.data.realChargePower,
            totalChargePower: res.data.totalChargePower,
            todayRanks: res.data.todayRanks,
            totalRanks: res.data.totalRanks,
          }
        })
      }
    }
  })

  const data = useMemo(() => {
    if (type === Type.今日) {
      return state?.midData?.todayRanks;
    } else {
      return state?.midData?.totalRanks;
    }
  }, [type, state?.midData])

  useEffect(() => {
    const timer = setTimeout(() => {
      const nextType = type === Type.累计 ? Type.今日 : type + 1;
      setType(nextType);
    }, 1000 * 60)

    return () => {
      clearTimeout(timer);
    }
  }, [type]);
  
  return (
    <Card 
      title='企业全部场站充电量排行榜TOP 5（单位：kW）' 
      style={{marginTop: 135..toScale(), width: 891..toScale()}}
      extra={<TabButtons<Type>
        btns={[
          {label: '今日', value: Type.今日},{label: '累计', value: Type.累计}
        ]}
        activeKey={type}
        onChange={setType}
      />}
    >
      <div className="sort-body">
        <div className="sort-item" style={{backgroundImage: `url(/img/sort1.png)`, color: 'rgba(255, 168, 46, 1)'}}>
          <img className='sort-award' src='/img/award1.png' alt='' />
          <div className="sort-number">TOP 1</div>
          <div className="sort-name">{data?.[0]?.stationName || ''}</div>
          <div className="sort-value" data-suffix={data?.[0]?.powerNum === undefined ? '' : ' kW'}>{data?.[0]?.powerNum?.toLocaleString() || ''}</div>
        </div>
        <div className="sort-item" style={{backgroundImage: `url(/img/sort2.png)`, color: 'rgba(190, 203, 221, 1)'}}>
          <img className='sort-award' src='/img/award2.png' alt='' />
          <div className="sort-number">TOP 2</div>
          <div className="sort-name">{data?.[1]?.stationName || ''}</div>
          <div className="sort-value" data-suffix={data?.[1]?.powerNum === undefined ? '' : ' kW'}>{data?.[1]?.powerNum?.toLocaleString() || ''}</div>
        </div>
        <div className="sort-item" style={{backgroundImage: `url(/img/sort3.png)`, color: 'rgba(242, 158, 116, 1)'}}>
          <img className='sort-award' src='/img/award3.png' alt='' />
          <div className="sort-number">TOP 3</div>
          <div className="sort-name">{data?.[2]?.stationName || ''}</div>
          <div className="sort-value" data-suffix={data?.[2]?.powerNum === undefined ? '' : ' kW'}>{data?.[2]?.powerNum?.toLocaleString() || ''}</div>
        </div>
        <div className="sort-item" style={{justifyContent: 'space-between'}}>
          <div className="sort-item2">
            <div className="sort-badge-ctx">4</div>
            <div className='sort-name2'>{data?.[3]?.stationName || ''}</div>
            <div className='sort-value2' data-suffix={data?.[3]?.powerNum === undefined ? '' : ' kW'}>{data?.[3]?.powerNum?.toLocaleString() || ''}</div>
          </div>
          <div className="sort-item2">
            <div className="sort-badge-ctx">5</div>
            <div className='sort-name2'>{data?.[4]?.stationName || ''}</div>
            <div className='sort-value2' data-suffix={data?.[4]?.powerNum === undefined ? '' : ' kW'}>{data?.[4]?.powerNum?.toLocaleString() || ''}</div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default SortInfo;
