import Alert from "@/components/Alert";
import { useRequest } from "ahooks";
import { useContext, useEffect, useMemo, useState } from "react";
import * as echarts from 'echarts';
import Card from "@/components/Card";
import request from "@/request";
import { StateContext } from "@/StateContext";
import { SUCCESS_CODE, FAKE_DATA } from "@/Constants";

const Colors = [
  'rgba(45, 155, 252, 1)',
  'rgba(108, 208, 106, 1)',
  'rgba(255, 222, 124, 1)',
  'rgba(255, 93, 93, 1)',
  'rgba(112, 94, 249, 1)',
  'rgba(93, 112, 146, 1)',
];

interface Data {
  /**
   * 数量
   */
  num: number;
  /**
   * 占比
   */
  rank: number;
  /**
   * 功率
   */
  rate: number;
}

async function fakeData() {
  return Promise.resolve({
    ...FAKE_DATA,
    data: [
      {num: 88888, rank: 16.66, rate: 100},
      {num: 88888, rank: 16.66, rate: 200},
      {num: 88888, rank: 16.66, rate: 300},
      {num: 88888, rank: 16.66, rate: 400},
      {num: 88888, rank: 16.66, rate: 500},
      {num: 88888, rank: 16.70, rate: 600},
    ]
  })
}

async function requestData(stationId: string): Promise<Result<Array<Data>>> {
  return fakeData();
  return request.run('/bigV1/stubKwRate', {
    method: 'GET', 
    params: {
      stationId
    }
  })
}

function draw(chart: echarts.ECharts, data: Array<Data>) {
  const value: any = [];
  const max = {
    value: 0, 
    index: 0,
  };
  data.forEach((it, idx) => {
    if (it.rank > max.value) {
      max.value = it.rank;
      max.index = idx;
    }
    value.push({
      value: it.rank,
      itemStyle: {
        color: Colors[idx]
      },
    })
  })
  value[max.index] = {
    value: max.value,
    itemStyle: {
      color: 'rgba(5, 207, 247, 0.2)',
    },
    children: [
      {
        value: max.value,
        itemStyle: {
          color: Colors[max.index],
        },
      }
    ]
  }
  const option = {
    nodeClick: false,
    series: {
      type: 'sunburst',
      data: value,
      radius: ['73%', '100%'],
      itemStyle: {
        borderColor: '#000',
        borderWidth: 1
      },
      label: {
        show: false
      }
    }
  };
  chart.setOption(option)
}

function TerminalPower() {
  const state = useContext(StateContext);
  const [chart, setChart] = useState<echarts.ECharts>();
  const { data, mutate } = useRequest(() => requestData(state?.stationId!), {
    ready: !!chart && !!state?.stationId,
    refreshDeps: [state?.stationId, state?.headerTabKey],
    pollingInterval: 1000 * 60,
    onSuccess: (res) => {
      if (res.code === SUCCESS_CODE) {
        const defaultData = [
          res.data[0] || {rate: 7, num: 0, rank: 0},
          res.data[1] || {rate: 30, num: 0, rank: 0},
          res.data[2] || {rate: 60, num: 0, rank: 0},
          res.data[3] || {rate: 90, num: 0, rank: 0},
          res.data[4] || {rate: 120, num: 0, rank: 0},
          res.data[5] || {rate: 180, num: 0, rank: 0},
        ]
        mutate({...res, data: defaultData});
        draw(chart!, res.data);
      }
    }
  })

  useEffect(() => {
    // 0 为默认值，等于 0 意味着还未获取到当前的屏幕宽度
    if (state?.width === 0) return;
    const chartDom = document.getElementById('terminalPowerPie');
    setChart(echarts.init(chartDom));
  }, [state?.width]);

  return (
    <Card title='终端功率分布'
      style={{marginTop: 94..toScale()}}
    >
      <div className="terminalPower">
        <div id='terminalPowerPie' className="terminalPowerPie"></div>
        <div className="terminalPowerPie">
          <img alt='' src='/img/pie-inset.png' />
          <div className="title">
            <span>终端功率</span>
            <span>分布</span>
          </div>
        </div>
        <Alert
          color={Colors[0]}
          data={{
            cannel: `${data?.data[0].rate || 7}kW`,
            percent: data?.data[0].rank || 0,
            total: `${data?.data[0].num || 0}`,
            suffix: '台'
          }}
          style={{position: 'absolute', top: 0}}
        />
        <Alert
          color={Colors[1]}
          data={{
            cannel: `${data?.data[1].rate || 30}kW`,
            percent: data?.data[1].rank || 0,
            total: `${data?.data[1].num || 0}`,
            suffix: '台'
          }}
          style={{position: 'absolute', top: 64..toScale()}}
        />
        <Alert
          color={Colors[2]}
          data={{
            cannel: `${data?.data[2].rate || 60}kW`,
            percent: data?.data[2].rank || 0,
            total: `${data?.data[2].num || 0}`,
            suffix: '台'
          }}
          style={{position: 'absolute', top: 128..toScale()}}
        />
        <div className='row'>
          <Alert
            color={Colors[3]}
            data={{
              cannel: `${data?.data[3].rate || 90}kW`,
              percent: data?.data[3].rank || 0,
              total: `${data?.data[3].num || 0}`,
              suffix: '台'
            }}
          />
          <Alert
            color={Colors[4]}
            data={{
              cannel: `${data?.data[4].rate || 120}kW`,
              percent: data?.data[4].rank || 0,
              total: `${data?.data[4].num || 0}`,
              suffix: '台'
            }}
          />
          <Alert
            color={Colors[5]}
            data={{
              cannel: `${data?.data[5].rate || 180}kW`,
              percent: data?.data[5].rank || 0,
              total: `${data?.data[5].num || 0}`,
              suffix: '台'
            }}
          />
        </div>
      </div>
    </Card>
  )
}

export default TerminalPower;
