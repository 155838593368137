import { CountUp } from 'countup.js';
import { useEffect, useRef } from 'react';

interface CounterProps {
  start: number;
  end: number;
}

const Counter: React.FC<CounterProps> = (props) => {
  const countupRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const countUpAnim = new CountUp(countupRef.current!, props.end, {
      startVal: props.start
    });
    if (!countUpAnim.error) {
      countUpAnim.start();
    } else {
      console.error(countUpAnim.error);
    }
  }, [props.start, props.end]);

  return (
    <span ref={countupRef}>{props.end}</span>
  )
}

export default Counter;