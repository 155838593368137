import { SUCCESS_CODE, FAKE_DATA } from "@/Constants";
import { useContext, useEffect } from "react";
import Card from "@/components/Card";
import { DispatchContext, StateContext } from "@/StateContext";
import { useRequest } from "ahooks";
import request from "@/request";

interface Data {
  /**
   * 数量
   */
  num: number;
  /**
   * 状态名称
   */
  title: string;
}

async function fakeData() {
  return Promise.resolve({
    ...FAKE_DATA,
    data: [
      {num: 100, title: '充电中'},
      {num: 10, title: '故障'},
      {num: 200, title: '空闲'},
      {num: 150, title: '占用'},
      {num: 20, title: '离线'},
      {num: 50, title: '其他'},
    ]
  })
}

async function requestData(stationId?: string): Promise<Result<Array<Data>>> {
  return fakeData();
  return request.run('/bigV1/realStubStatusNum', {
    method: 'GET', 
    params: {
      stationId
    }
  })
}

let flag: number | null = null;

function DeviceStatus() {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const { data, mutate } = useRequest(() => requestData(state?.stationId), {
    ready: !!state?.stationId,
    refreshDeps: [state?.stationId, state?.headerTabKey],
    pollingInterval: 1000 * 60,
    onSuccess: (res) => {
      if (res.code === SUCCESS_CODE) {
        const sortData: Array<Data> = [];
        res.data.forEach(item => {
          switch (item.title) {
            case '空闲': sortData[0] = item; break;
            case '充电中': sortData[1] = item; break;
            case '其他': sortData[2] = item; break;
            case '故障': sortData[3] = item; break;
            case '占用': sortData[4] = item; break;
            case '离线': sortData[5] = item; break;
          }
        })
        mutate({...res, data: sortData});
        // 计算实时设备状态占比数据
        const total = res.data.reduce((a, b) => a + b.num, 0) || 1;
        dispatch?.({
          type: 'setDeviceStatusPercent',
          payload: sortData.map(it => ({label: it.title, value: Math.round(it.num / total * 100)}))
        })
      }
    }
  })
  
  useEffect(() => {
    return () => {
      if (flag !== null) {
        cancelAnimationFrame(flag);
        flag = null;
      }
    }
  }, []);

  return (
    <Card 
      title='实时设备状态' 
      style={{marginTop: 84..toScale(), marginLeft: 70..toScale(), width: 740..toScale()}}
    >
      <div className="devicesStatus">
        <div className="device-bg" id="device-bg" style={{backgroundImage: `url(/img/device-bg.png)`}} />
        <div className="box">
          <div className="item" style={{'--start': '0px', '--end': '-92px', backgroundImage: 'url(/img/free.png)'} as any}>
            <span>{data?.data[0]?.title || '空闲'}</span>
            <span className="count">{data?.data[0]?.num.toLocaleString() || 0}</span>
          </div>
          <div className="item" style={{'--start': '-60px', '--end': '-122px', backgroundImage: 'url(/img/charging.png)'} as any}>
            <span>{data?.data[1]?.title || '充电中'}</span>
            <span className="count">{data?.data[1]?.num.toLocaleString() || 0}</span>
          </div>
          <div className="item" style={{'--start': '0px', '--end': '-92px', backgroundImage: 'url(/img/other.png)'} as any}>
            <span>{data?.data[2]?.title || '其他'}</span>
            <span className="count">{data?.data[2]?.num.toLocaleString() || 0}</span>
          </div>
        </div>
        <div className="box box-right">
          <div className="item" style={{'--start': '0px', '--end': '92px', backgroundImage: 'url(/img/warning.png)'} as any}>
            <span className="count">{data?.data[3]?.num.toLocaleString() || 0}</span>
            <span>{data?.data[3]?.title || '故障'}</span>
          </div>
          <div className="item" style={{'--start': '60px', '--end': '122px', backgroundImage: 'url(/img/using.png)'} as any}>
            <span className="count">{data?.data[4]?.num.toLocaleString() || 0}</span>
            <span>{data?.data[4]?.title || '占用'}</span>
          </div>
          <div className="item" style={{'--start': '0px', '--end': '92px', backgroundImage: 'url(/img/outline.png)'} as any}>
            <span className="count">{data?.data[5]?.num.toLocaleString() || 0}</span>
            <span>{data?.data[5]?.title || '离线'}</span>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default DeviceStatus;
