import { useEffect, useState } from "react";
import './index.less';

interface BtnInfo<T> {
  label: string;
  value: T;
}

interface TabButtonsProps<T> extends React.PropsWithChildren {
  btns: Array<BtnInfo<T>>;
  style?: React.CSSProperties;
  minWidth?: number;
  activeKey?: T;
  onChange?: (key: T) => void;
  defaultActiveKey?: T;
}

function TabButtons<T extends React.Key>(props: TabButtonsProps<T>) {
  const [activeKey, setActiveKey] = useState<T>(props.defaultActiveKey || props.btns[0].value);

  const onChange = (key: T) => {
    if (props.onChange) {
      props.onChange(key);
    }
    if (props.activeKey === undefined) {
      setActiveKey(key);
    }
  }

  useEffect(() => {
    if (props.activeKey !== undefined) {
      setActiveKey(props.activeKey);
    }
  }, [props.activeKey]);

  return (
    <div className="tabButtons" style={props.style}>
      {
        props.btns.map((btn) => {
          return <div 
            className={`btn ${activeKey === btn.value ? 'active' : ''}`} 
            key={btn.value}
            onClick={() => onChange(btn.value)}
            style={{minWidth: (props.minWidth || 82).toScale()}}
          >
            {btn.label}
          </div>
        })
      }
    </div>
  )
}

export default TabButtons;
