import './index.less';

interface TabButtonProps extends React.PropsWithChildren {
  active: boolean;
  onClick: () => void;
}

const TabButton: React.FC<TabButtonProps> = (props) => {
  if (props.active) {
    return (
      <div className="tabButton tab-active" style={{backgroundImage: 'url(/img/tab-btn-bg-active.png)'}} onClick={props.onClick}>
        <img className='tab-btn-outer-left' src='/img/tab-btn-outer-left-active.png' alt='' />
        <img className='tab-btn-inner-left' src='/img/tab-btn-inner-left-active.png' alt='' />
        {props.children}
        <img className='tab-btn-inner-right' src='/img/tab-btn-inner-right-active.png' alt='' />
        <img className='tab-btn-outer-right' src='/img/tab-btn-outer-right-active.png' alt='' />
        <img className='tab-btn-bottom' src='/img/tab-btn-bottom-active.png' alt='' />
      </div>
    )
  }
  return (
    <div className="tabButton" style={{backgroundImage: 'url(/img/tab-btn-bg.png)'}} onClick={props.onClick}>
      <img className='tab-btn-outer-left' src='/img/tab-btn-outer-left.png' alt='' />
      <img className='tab-btn-inner-left' src='/img/tab-btn-inner-left.png' alt='' />
      {props.children}
      <img className='tab-btn-inner-right' src='/img/tab-btn-inner-right.png' alt='' />
      <img className='tab-btn-outer-right' src='/img/tab-btn-outer-right.png' alt='' />
      <img className='tab-btn-bottom' src='/img/tab-btn-bottom.png' alt='' />
    </div>
  )
}

export default TabButton;
