import { FAKE_DATA, SUCCESS_CODE } from "@/Constants";
import { StateContext } from "@/StateContext";
import Card from "@/components/Card";
import request from "@/request";
import { useRequest } from "ahooks";
import * as echarts from 'echarts';
import { useContext, useEffect, useMemo, useState } from "react";
import { getPie3D, play3DPieAnimation } from "@/utils";
import Alert from '@/components/Alert';

enum TypeColor {
  支付宝 = 'rgba(45, 155, 252, 1)',
  微信 = 'rgba(108, 208, 106, 1)',
  'Apple Pay' = 'rgba(255, 222, 124, 1)',
  银行卡 = 'rgba(255, 93, 93, 1)',
}

type Data = Array<{
  /**
   * 支付总金额
   */
  money: number;
  /**
   * 占比
   */
  rank: number;
  /**
   * 支付渠道
   */
  title: string;
}>;

type KV = {
  money: number;
  percent: number;
}

type Formatter = {
  支付宝: KV;
  微信: KV;
  'Apple Pay': KV;
  银行卡: KV;
}

async function fakeData() {
  return Promise.resolve({
    ...FAKE_DATA,
    data: [
      {"money": Math.floor(Math.random() * 1000000 + 10000), "rank": parseFloat((Math.random() * 100 + 10).toFixed(2)), "title": "支付宝"},
      {"money": Math.floor(Math.random() * 1000000 + 10000), "rank": parseFloat((Math.random() * 100 + 10).toFixed(2)), "title": "微信"},
      {"money": Math.floor(Math.random() * 1000000 + 10000), "rank": parseFloat((Math.random() * 100 + 10).toFixed(2)), "title": "Apple Pay"},
      {"money": Math.floor(Math.random() * 1000000 + 10000), "rank": parseFloat((Math.random() * 100 + 10).toFixed(2)), "title": "银行卡"},
    ]
  })
}

async function requestData(stationId?: string): Promise<Result<Data>> {
  return fakeData();
  return request.run('/bigV1/chargeMoneyAnalysis', {
    method: 'GET', 
    params: {
      stationId
    }
  })
}

function draw(chart: echarts.ECharts, data: Formatter) {
  const option = getPie3D([{
    name: '支付宝',
    value: data.支付宝.percent,
    itemStyle: {
      color: TypeColor.支付宝
    }
  }, {
    name: '微信',
    value: data.微信.percent,
    itemStyle: {
      color: TypeColor.微信,
    }
  }, {
    name: 'Apply Pay',
    value: data["Apple Pay"].percent,
    itemStyle: {
      color: TypeColor["Apple Pay"]
    }
  }, {
    name: '银行卡',
    value: data.银行卡.percent,
    itemStyle: {
      color: TypeColor.银行卡
    }
  }], 0.7);

  chart.setOption(option);
  play3DPieAnimation(chart);
}

function PayTypeAnalysis() {
  const state = useContext(StateContext);
  const [chart, setChart] = useState<echarts.ECharts>();
  const { data, run } = useRequest(() => requestData(state?.stationId), {
    ready: !!chart && state?.stationId === '_global',
    refreshDeps: [state?.stationId, state?.headerTabKey],
  })

  const formatter = useMemo(() => {
    const result: Formatter = {
      支付宝: {
        money: 0,
        percent: 0,
      },
      微信: {
        money: 0,
        percent: 0,
      },
      'Apple Pay': {
        money: 0,
        percent: 0,
      },
      银行卡: {
        money: 0,
        percent: 0,
      }
    }
    if (data?.code === SUCCESS_CODE) {
      data.data.forEach(it => {
        switch (it.title) {
          case '支付宝': {
            result.支付宝.money = it.money;
            result.支付宝.percent = it.rank;
          } break;
          case '微信': {
            result.微信.money = it.money;
            result.微信.percent = it.rank;
          } break;
          case 'Apple Pay': {
            result['Apple Pay'].money = it.money;
            result['Apple Pay'].percent = it.rank;
          } break;
          case '银行卡': {
            result.银行卡.money = it.money;
            result.银行卡.percent = it.rank;
          } break;
          default: break;
        }
      })
      if (chart) {
        draw(chart, result);
      }
    }
    return result;
  }, [data, chart]);

  useEffect(() => {
    if (state?.needRefreshFor0100) {
      run();
    }
  }, [state?.needRefreshFor0100]);

  useEffect(() => {
    // 0 为默认值，等于 0 意味着还未获取到当前的屏幕宽度
    if (state?.width === 0) return;
    if (state?.stationId === '_global') {
      const chartDom = document.getElementById('payAnalysisChart');
      setChart(echarts.init(chartDom));
    } else {
      chart?.dispose();
      setChart(undefined);
    }
  }, [state?.stationId, state?.width]);

  return (
    <Card title='支付类型信息分析' style={{marginTop: 58..toScale()}}>
      {
        state?.stationId === '_global'
        ? (
          <div key='payTypePie' className='payTypePie'>
            <div id='payAnalysisChart'></div>
            <Alert
              color={TypeColor.支付宝}
              data={{
                cannel: '支付宝',
                percent: formatter.支付宝.percent,
                total: formatter.支付宝.money.toFixed(2)
              }}
              style={{position: 'absolute', top: 58..toScale()}}
            />
            <div className='row'>
              <Alert
                color={TypeColor.微信}
                data={{
                  cannel: '微信',
                  percent: formatter.微信.percent,
                  total: formatter.微信.money.toFixed(2)
                }}
              />
              <Alert
                color={TypeColor["Apple Pay"]}
                data={{
                  cannel: 'Apple Pay',
                  percent: formatter["Apple Pay"].percent,
                  total: formatter["Apple Pay"].money.toFixed(2)
                }}
              />
              <Alert
                color={TypeColor.银行卡}
                data={{
                  cannel: '银行卡',
                  percent: formatter.银行卡.percent,
                  total: formatter.银行卡.money.toFixed(2)
                }}
              />
            </div>
          </div>
        ) : (
          <div key='payTypeEmpty' className="payTypeEmpty">
            <img alt='' src='/img/empty.png' />
            <span>切换至全局模式查看支付类型信息分析数据</span>
          </div>
        )
      }
    </Card>
  )
}

export default PayTypeAnalysis;
