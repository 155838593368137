import { useContext, useEffect, useMemo, useState } from "react";
import Card from "../Card";
import Label from "../Label";
import { useRequest } from "ahooks";
import { StateContext } from "@/StateContext";
import request from "@/request";
import * as echarts from 'echarts';
import { generateYAxis, getMaxValue } from "@/utils";
import { FAKE_DATA, SUCCESS_CODE } from "@/Constants";

interface KV {
  day: string;
  num: number;
}
interface Data {
  dayChargeList: Array<KV>;
  stubChargeList: Array<KV>;
}

async function fakeData() {
  return Promise.resolve({
    ...FAKE_DATA,
    data: {
      dayChargeList: new Array(30).fill(1).map((_,i) => ({day: `${i}`, num: Math.floor(Math.random() * 200 + 30)})),
      stubChargeList: new Array(30).fill(1).map((_,i) => ({day: `${i}`, num: Math.floor(Math.random() * 1000 + 200)}))
    }
  })
}

async function requestData(stationId?: string): Promise<Result<Data>> {
  return fakeData();
  return request.run('/bigV1/thirtyData', {
    method: 'GET', 
    params: {
      stationId
    }
  })
}

function draw(chart: echarts.ECharts, data: Data) {
  const dayChargeList = data.dayChargeList.map(it => it.num);
  const stubChargeList = data.stubChargeList.map(it => it.num);
  const maxValue = getMaxValue(dayChargeList.concat(stubChargeList));

  const x = new Array(30).fill(1).map((_, i) => `${i + 1}`);

  const option = {
    xAxis: {
      type: 'category',
      boundaryGap: [20..toScale(), 20..toScale()],
      data: x,
      axisTick: {
        show: false,
      },
      axisLabel: {
        interval: (i: number) => i === 0 || i % 5 === 4,
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: 'rgba(255,255,255,0.1)',
        }
      },
      axisLabel: {
        show: false,
      },
      max: maxValue, 
      interval: maxValue / 4,
    },
    grid: {
      top: 10..toScale2(),
      left: 40..toScale(), 
      right: 20..toScale(),
      bottom: 20..toScale2(),
    },
    series: [
      {
        data: stubChargeList,
        type: 'line',
        smooth: true,
        symbol: 'none',
        itemStyle: {
          color: '#78FFB7',
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: 'rgba(120,255,183,0.15)' // 0% 处的颜色
            }, {
                offset: 1, color: 'rgba(120,255,183,0)' // 100% 处的颜色
            }],
            global: false // 缺省为 false
          }
        }
      },
      {
        data: dayChargeList,
        type: 'line',
        smooth: true,
        symbol: 'none',
        itemStyle: {
          color: '#2D9BFC',
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: 'rgba(45,155,252,0.15)' // 0% 处的颜色
            }, {
                offset: 1, color: 'rgba(45,155,252,0)' // 100% 处的颜色
            }],
            global: false // 缺省为 false
          }
        }
      },
    ]
  };
  chart.setOption(option);
  generateYAxis(chart, maxValue);
}

function ThirtyChargeData() {
  const state = useContext(StateContext);
  const [chart, setChart] = useState<echarts.ECharts>();
  const req = useRequest(() => requestData(state?.stationId), {
    ready: !!chart && !!state?.stationId,
    refreshDeps: [state?.stationId, state?.headerTabKey],
    onSuccess: (res) => {
      if (res.code === SUCCESS_CODE) {
        draw(chart!, res.data);
      }
    }
  })

  const dotStyle = useMemo(() => ({
    width: 6..toScale(),
    height: 6..toScale(),
    borderRadius: '50%',
  }), []);

  useEffect(() => {
    if (state?.needRefreshFor0100) {
      req.run();
    }
  }, [state?.needRefreshFor0100]);

  useEffect(() => {
    // 0 为默认值，等于 0 意味着还未获取到当前的屏幕宽度
    if (state?.width === 0) return;
    const chartDom = document.getElementById('chartDom');
    if (!chartDom) return;
    setChart(echarts.init(chartDom));
  }, [state?.width]);

  return (
    <Card title='近30天充电趋势' style={{marginTop: 10..toScale2()}}>
      <div className='chargeTrend'>
        <div className='label-row'>
          <Label>
            <span>单位: 次</span>
          </Label>
          <div style={{display: 'flex', gap: 16..toScale()}}>
            <Label>
              <div style={{...dotStyle, backgroundColor: '#2D9BFC'}}></div>
              <span>充电量</span>
            </Label>
            <Label>
              <div style={{...dotStyle, backgroundColor: '#78FFB7'}}></div>
              <span>单枪日冲</span>
            </Label>
          </div>
        </div>
        <div id='chartDom'></div>
      </div>
    </Card>
  );
}

export default ThirtyChargeData;
