import { useContext } from 'react';
import { StateContext } from '@/StateContext';
import Access from '@/components/Access';
import ChargeTab from '@/components/ChargeTab';
import OperationsTab from '@/components/OperationsTab';
import EnergyTab from '@/components/EnergyTab';

interface ContainerProps {
  style?: React.CSSProperties;
}

const Container: React.FC<ContainerProps> = (props) => {
  const state = useContext(StateContext);

  return (
    <div style={{...props.style}}>
      <Access accessible={state?.headerTabKey === 'chargeData'}>
        <ChargeTab />
      </Access>
      <Access accessible={state?.headerTabKey === 'operationsData'}>
        <OperationsTab />
      </Access>
      <Access accessible={state?.headerTabKey === 'energyData'}>
        <EnergyTab />
      </Access>
    </div>
  )
}

export default Container;
