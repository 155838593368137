import Select from '@/components/Select';
import NewUserCount from './NewUserCount';
import ChargeCount from './ChargeCount';
import Map from './Map';
import ThirtyChargeData from './ThirtyChargeData';
import CardTypePercent from './CardTypePercent';
import ChargeTypeAnalysis from './ChargeTypeAnalysis';
import PayTypeAnalysis from './PayTypeAnalysis';
import StatisticsData from './StatisticsData';
import SortInfo from './SortInfo';
import ChargeAnalysis from './ChargeAnalysis';
import { HEADER_TAB_KEY } from '@/Constants';

const ChargeTab: React.FC = () => {
  return (
    <div className="container">
      <div className='containerLeft'>
        <ThirtyChargeData />
        <CardTypePercent />
        <ChargeTypeAnalysis />
        <PayTypeAnalysis />
      </div>
      <div className="containerMid">
        <Select />
        <StatisticsData />
        <Map type={HEADER_TAB_KEY.CHARGE_DATA} />
        <SortInfo />
      </div>
      <div className="containerRight">
        <NewUserCount />
        <ChargeCount />
        <ChargeAnalysis />
      </div>
    </div>
  )
}

export default ChargeTab;
