interface BaseOptions {
  /**
   * 请求头
   */
  headers?: {[key: string]: any};
  /**
   * 是否格式化返回数据
   */
  format?: boolean;
  /**
   * 格式化返回数据的配置
   */
  formatConfig?: {
    /**
     * code字段名, 默认code
     */
    codeKey: string;
    /**
     * data字段名, 默认data
     */
    dataKey: string;
    /**
     * message字段名, 默认message
     */
    msgKey: string;
  }
}

/**
   * 构造参数
   */
interface Config extends BaseOptions {
  /**
   * 成功状态码
   */
  successCode?: (string | number)[];
  /**
   * 请求主机（域名）
   */
  host?: string;
  /**
   * 错误处理函数 
   */
  errHandler?: (err: any) => void;
}

/**
   * 请求参数
   */
interface RequestOptions extends BaseOptions {
  /**
   * 请求方式
   */
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  /**
   * 请求参数
   */
  params?: {[key: string]: any};
  /**
   * 是否统一处理错误，默认true 
   */
  errHandler?: boolean;
  /**
   * 文件流
   */
  fileStream?: boolean;
}

const getBody = (options: RequestOptions) => {
  if (options.params instanceof FormData) {
    return options.params;
  }
  if (options.method !== 'GET') {
    return JSON.stringify(options.params || {});
  }
  return undefined;
}

/**
 * http请求工具
 */
class Request {

  /**
   * 成功状态码
   */
  private _successCode: (string | number)[] = ['200', '0000', 200];
  
  /**
   * 请求主机（域名）
   */
  private _host: string = '';

  /**
   * 格式化请求结果
   */
  private _format: boolean = true;

  /**
   * 格式化请求结果的配置
   */
  formatConfig: Config['formatConfig'] = {
    codeKey: 'code',
    dataKey: 'data',
    msgKey: 'message'
  }

  /**
   * 请求头 
   */
  private _headers: Config['headers'] = {
    "Content-Type": 'application/json'
  }

  /**
   * 错误处理函数 
   */
  private _errHandler: undefined | ((err: any) => void) = undefined;

  /**
   * 构造函数
   * @param config 
   */
  constructor (config: Config) {
    if (config.successCode) {
      this._successCode = config.successCode;
    }
    if (config.host) {
      this._host = config.host;
    }
    if (config.format === false) {
      this._format = false;
    }
    if (config.formatConfig) {
      this.formatConfig = {
        ...this.formatConfig,
        ...config.formatConfig
      }
    }
    if (config.headers) {
      this._headers = {
        ...this._headers,
        ...config.headers,
      }
    }
    if (config.errHandler) {
      this._errHandler = config.errHandler;
    }
  }

  /**
   * 请求函数
   * @param options 
   */
  run<T = unknown>(url: string, options: RequestOptions = {method: 'GET'}): Promise<T> {
    return new Promise((resolve, reject) => {
      if (!url) {
        reject('请求地址不能为空');
        return;
      }
      if (!options.method) {
        options.method = 'GET';
      }
      if (options.params) {
        // 全局产站时，删除 stationId 字段
        if (options.params.stationId === '_global') {
          delete options.params.stationId;
        }
        if (options.method === 'GET') {
          const urlParams = new URLSearchParams(options.params);
          url += '?' + urlParams.toString();
        }
      }
      const _headers: {[key: string]: string} = {
        ...this._headers,
        ...options.headers,
      };

      Object.keys(_headers).forEach((k) => _headers[k] == null && delete _headers[k]);

      fetch(this._host + url, {
        method: options.method,
        headers: _headers,
        mode: 'cors',
        credentials: 'include',
        body: getBody(options),
      })
      .then(response => {
        if (response.ok) {
          if (options.fileStream) {
            return response;
          }
          return response.json().catch(err => {
            console.error(err);
            return {
              code: '500',
              text: '服务器返回数据格式错误',
            }
          });
        }
        return Promise.reject(response);
      })
      .then(data => {
        if (options.format === false || this._format === false) {
          resolve(data);
        } else {
          const _formatConfig = options.formatConfig || this.formatConfig;
          if (this._successCode.includes(data[_formatConfig!.codeKey])) {
            resolve(data[_formatConfig!.dataKey]);
          } else {
            if (options.errHandler !== false) {
              this._errHandler?.(data);
            }
            reject(data);
          }
        }
      })
      .catch(err => {
        if (options.errHandler !== false) {
          this._errHandler?.(err);
        }
        reject(err);
      })
    });
  }
}

const request = new Request({
  format: false,
  host: process.env.REACT_APP_HOST,
});

export default request;
