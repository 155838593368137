import { StateContext } from "@/StateContext";
import { useContext, useEffect, useState } from "react";
import * as echarts from 'echarts';

function getOption(name: string, value: number, color1: string, color2: string) {
  return {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false
        },
        radius: '100%',
        progress: {
          show: true,
          overlap: false,
          roundCap: false,
          clip: false,
          itemStyle: {
            borderWidth: 8,
            borderColor: 'transparent',
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                  offset: 0, color: color1 // 0% 处的颜色
              }, {
                  offset: 1, color: color2 // 100% 处的颜色
              }],
              global: false // 缺省为 false
            },
          }
        },
        axisLine: {
          lineStyle: {
            width: 6..toScale(),
            color: [
              [1, 'transparent']
            ],
          }
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false,
        },
        data: [
          {
            value: value,
            name: name,
            title: {
              offsetCenter: ['0%', 15..toScale()]
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ['0%', -10..toScale()]
            }
          },
        ],
        title: {
          show: true,
          color: 'rgba(255, 255, 255, 0.60)',
          fontSize: 14..toScale(),
          fontWeight: 400,
        },
        detail: {
          formatter: '{a|{value}}{b|%}',
          rich: {
            a: {
              fontSize: 20..toScale(),
              fontWeight: 800,
              color: 'rgba(168, 208, 237, 1)',
              align: 'center',
              padding: [0, 0, 0, 0]
            },
            b: {
              fontSize: 12..toScale(),
              fontWeight: 800,
              color: 'rgba(168, 208, 237, 1)',
              align: 'center',
              padding: [7..toScale(), 0, 0, 0]
            }
          }
        }
      }
    ]
  }
}

function DevicePercent() {
  const [chartObj, setChartObj] = useState<Record<string, echarts.ECharts>>();
  const state = useContext(StateContext);

  useEffect(() => {
    if (!chartObj || !state?.deviceStatusPercent) return;

    const d = state.deviceStatusPercent;
    chartObj.chart1.setOption(getOption(d[0].label, d[0].value, 'rgba(25, 120, 229, 1)', 'rgba(0, 213, 255, 1)'));
    chartObj.chart2.setOption(getOption(d[1].label, d[1].value, 'rgba(87, 239, 227, 1)', 'rgba(101, 244, 181, 1)'));
    chartObj.chart3.setOption(getOption(d[2].label, d[2].value, 'rgba(255, 147, 76, 1)', 'rgba(255, 196, 80, 1)'));
    chartObj.chart4.setOption(getOption(d[3].label, d[3].value, 'rgba(165, 189, 220, 1)', 'rgba(209, 224, 240, 1)'));
    chartObj.chart5.setOption(getOption(d[4].label, d[4].value, 'rgba(255, 94, 98, 1)', 'rgba(255, 153, 102, 1)'));
    chartObj.chart6.setOption(getOption(d[5].label, d[5].value, 'rgba(23, 123, 238, 1)', 'rgba(5, 196, 250, 1)'));
  }, [chartObj, state?.deviceStatusPercent]);

  useEffect(() => {
    if (chartObj) return;
    // 0 为默认值，等于 0 意味着还未获取到当前的屏幕宽度
    if (state?.width === 0) return;
    
    const chartDom1 = document.getElementById('devicePercentPie1');
    const chartDom2 = document.getElementById('devicePercentPie2');
    const chartDom3 = document.getElementById('devicePercentPie3');
    const chartDom4 = document.getElementById('devicePercentPie4');
    const chartDom5 = document.getElementById('devicePercentPie5');
    const chartDom6 = document.getElementById('devicePercentPie6');

    setChartObj({
      chart1: echarts.init(chartDom1),
      chart2: echarts.init(chartDom2),
      chart3: echarts.init(chartDom3),
      chart4: echarts.init(chartDom4),
      chart5: echarts.init(chartDom5),
      chart6: echarts.init(chartDom6),
    })
  }, [state?.width]);

  return (
    <div className="devicePercent">
      <div className="box">
        <div className="item">
          <div className="devicePercentPie" id="devicePercentPie1"></div>
          <img alt='' src='/img/pie-inset-bg.png' />
        </div>
        <div className="item">
          <div className="devicePercentPie" id="devicePercentPie2"></div>
          <img alt='' src='/img/pie-inset-bg.png' />
        </div>
        <div className="item">
          <div className="devicePercentPie" id="devicePercentPie3"></div>
          <img alt='' src='/img/pie-inset-bg.png' />
        </div>
      </div>
      <div className="box">
        <div className="item">
          <div className="devicePercentPie" id="devicePercentPie4"></div>
          <img alt='' src='/img/pie-inset-bg.png' />
        </div>
        <div className="item">
          <div className="devicePercentPie" id="devicePercentPie5"></div>
          <img alt='' src='/img/pie-inset-bg.png' />
        </div>
        <div className="item">
          <div className="devicePercentPie" id="devicePercentPie6"></div>
          <img alt='' src='/img/pie-inset-bg.png' />
        </div>
      </div>
    </div>
  )
}

export default DevicePercent;
