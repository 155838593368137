import './index.less';

interface AlertData {
  label: string;
  total: string;
}

interface AlertProps extends React.PropsWithChildren {
  style?: React.CSSProperties;
  color: string;
  data: AlertData;
}

const Alert: React.FC<AlertProps> = (props) => {
  return (
    <div className='alert2' style={{...props.style, '--alert-color': props.color} as any}>
      <div className='category'>
        <span>{props.data.label}</span>
      </div>
      <div className='value'>
        <span>{props.data.total}</span>
      </div>
    </div>
  )
}

export default Alert;
