/** 最小宽度 */
export const MIN_WIDTH =  2160;
/** 全局宽高比 */
export const  GLOBAL_ASPECT_RATIO = 2;
/** 头部宽高比 */
export const HEADER_ASPECT_RATIO = 2160 / 70;
/** 顶部流光 canvas 速度, 每次渲染一种的像素量 */
export const FLOW_LIGHT_CANVAS_SPEED = 3;
/** 顶部流光 canvas 小球尺寸，小球半径长度：像素 */
export const FLOW_LIGHT_CANVAS_BALL_SIZE = 3;
/** API 请求成功 code */
export const SUCCESS_CODE = '200';
/** 头部 Header Tabs key */
export enum HEADER_TAB_KEY {
  /** 充电数据 */
  CHARGE_DATA = 'chargeData',
  /** 运维数据 */
  OPERATIONS_DATA = 'operationsData',
  /** 能源数据 */
  ENERGY_DATA = 'energyData',
}
/** 
 * 帧图播放速度
 * 数值越大播放速度越慢
 * requestAnimationFrame 每执行 SECONDS_PER_FRAME 次，渲染一张帧图
 */
export const SECONDS_PER_FRAME = 5;
/**
 * 假数据
 */
export const FAKE_DATA = {
  code: SUCCESS_CODE,
  currentPageNo: 1,
  pageLength: 1,
  text: 'success',
  totalCount: 10,
}
