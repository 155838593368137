import { useContext, useEffect, useState } from 'react';
import './index.less';
import { DispatchContext } from '@/StateContext';
import { useRequest } from 'ahooks';
import request from '@/request';
import { SUCCESS_CODE, FAKE_DATA } from '@/Constants';

interface Data {
  /**
   * 场站ID
   */
  stationId: string;
  /**
   * 场站名称
   */
  stationName: string;
}

async function fakeData() {
  return Promise.resolve({
    ...FAKE_DATA,
    data: [{
      stationId: '1',
      stationName: '1',
    },{
      stationId: '2',
      stationName: '2',
    }]
  })
}

async function requestData(pageNum: number): Promise<Result<Array<Data>>> {
  return fakeData();
  return request.run('/bigV1/stationList', {
    method: 'GET', 
    params: {
      pageNum,
      pageSize: 20,
    }
  })
}

function Select() {
  const dispatch = useContext(DispatchContext)!;
  const [data, setData] = useState<Array<Data>>([{stationName: '全局数据', stationId: '_global'}]);
  const [pageNum, setPageNum] = useState<number>(1);
  const req = useRequest(requestData, {
    defaultParams: [pageNum],
    debounceWait: 200,
    onSuccess: (res) => {
      if (res.code === SUCCESS_CODE) {
        setPageNum(pageNum + 1);
        setData(data.concat(res.data));
      }
    }
  });
  
  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState<Data>({stationName: '全局数据', stationId: '_global'});

  const onTitleClick = (e: Event) => {
    e.stopPropagation();
    setShow(!show);
  }

  const onPanelClick = (e: Event) => {
    e.stopPropagation();
  }

  const onChange = (value: Data) => {
    setCurrent(value);
    setShow(false);
    dispatch({
      type: 'setStationId',
      payload: {
        stationId: value.stationId
      }
    })
  }

  useEffect(() => {
    const listener = (e: Event) => {
      setShow(false);
    }
    window.addEventListener('click', listener);

    const obListener = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      const entry = entries[0];
      if (entry.isIntersecting && pageNum <= (Math.ceil((req.data?.totalCount || 0) / (req.data?.pageLength || 20)) || 1)) {
        req.run(pageNum);
      }
    }
    const observer = new IntersectionObserver(obListener, {
      root: document.getElementById('select-options'),
      rootMargin: `0px 0px ${5 * 40..toScale()}px 0px`,
    })
    const loadMoreDom = document.getElementById('loadMore');
    if (loadMoreDom) {
      observer.observe(loadMoreDom);
    }
  
    return () => {
      window.removeEventListener('click', listener);
      if (loadMoreDom) {
        observer.unobserve(loadMoreDom);
      }
      observer.disconnect();
    }
  }, [pageNum]);

  return (
    <div className="select" style={{background: 'url(/img/select-bg.png) 100% no-repeat'}}>
      <div className='select-title' onClick={onTitleClick as any}>
        <span>{current.stationName}</span>
        <img alt='' src='/img/select-icon.png' />
      </div>
      <div className="select-options" id='select-options' style={{display: show ? 'flex' : 'none'}} onClick={onPanelClick as any}>
        {
          data.map((item, index) => {
            return (
              <div key={index} className="item" onClick={() => onChange(item)}>
                <span>{item.stationName}</span>
                <img alt='' src='/img/select-check.png' style={{opacity: current.stationId === item.stationId ? '1' : '0'}} />
              </div>
            )
          })
        }
        <div className="item" id='loadMore' style={{display: (Math.ceil((req.data?.totalCount || 0) / (req.data?.pageLength || 20)) || 1) >= pageNum ? 'flex' : 'none'}}>加载更多</div>
      </div>
    </div>
  )
}

export default Select;
