import './index.less';

interface CardProps extends React.PropsWithChildren {
  title: string;
  style?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  extra?: React.ReactNode;
}

const Card: React.FC<CardProps> = (props) => {
  return (
    <div className="card" style={props.style}>
      <div className="card-header">
        <div className="card-title" style={{backgroundImage: 'url(/img/card-title-bg.png)'}}>
          <img className='card-title-icon' src='/img/card-title-icon.png' alt='' />
          <span>{props.title}</span>
        </div>
        <div className='card-extra'>{props.extra}</div>
      </div>
      <div className='card-content' style={props.bodyStyle}>
        {props.children}
      </div>
    </div>
  )
}

export default Card;
