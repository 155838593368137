import { SUCCESS_CODE, FAKE_DATA } from "@/Constants";
import { StateContext } from "@/StateContext";
import TabButtons from "@/components/TabButtons";
import request from "@/request";
import { useRequest } from "ahooks";
import { useContext, useEffect, useRef, useState } from "react";

const enum DeviceStatus {
  '充电中' = 3,
  '故障' = 255,
  '空闲' = 1,
  '占用' = 2,
  '离线' = 0,
  '其他' = 5
}

const getNextTab = (currentTab: DeviceStatus) => {
  switch (currentTab) {
    case DeviceStatus.充电中:
      return DeviceStatus.故障;
    case DeviceStatus.故障:
      return DeviceStatus.空闲;
    case DeviceStatus.空闲:
      return DeviceStatus.占用;
    case DeviceStatus.占用:
      return DeviceStatus.离线;
    case DeviceStatus.离线:
      return DeviceStatus.其他;
    case DeviceStatus.其他:
      return DeviceStatus.充电中;
    default:
      return DeviceStatus.充电中;
  }
}

interface Data {
  /** 充电中比例，其他状态没有 */
  rank: number;
  /** 充电中的实时功率，其他状态没有 */
  kwLimit: number;
  /** 场站名称 */
  stationName: string;
  /** 设备编号 */
  id: string;
}

interface Params {
  pageNum: number;
  pageSize: number;
  status: DeviceStatus;
  stationId?: string;
}

/** canvas 动画 */
let flag: number | null = null;
/** canvas 偏移量 */
let offsetCount = 0;
let recordIndex = 0;
// 借助全局变量，实现获取下一页数据的参数
let nextParams: Params;

async function fakeData() {
  return Promise.resolve({
    ...FAKE_DATA,
    data: [
      {rank: 60, kwLimit: 600, stationName: '常州站00', id: 'czs00'},
      {rank: 61, kwLimit: 601, stationName: '常州站01', id: 'czs01'},
      {rank: 62, kwLimit: 602, stationName: '常州站02', id: 'czs02'},
      {rank: 63, kwLimit: 603, stationName: '常州站03', id: 'czs03'},
      {rank: 64, kwLimit: 604, stationName: '常州站04', id: 'czs04'},
      {rank: 65, kwLimit: 605, stationName: '常州站05', id: 'czs05'},
      {rank: 66, kwLimit: 606, stationName: '常州站06', id: 'czs06'},
      {rank: 67, kwLimit: 607, stationName: '常州站07', id: 'czs07'},
      {rank: 68, kwLimit: 608, stationName: '常州站08', id: 'czs08'},
      {rank: 69, kwLimit: 609, stationName: '常州站09', id: 'czs09'},
      {rank: 70, kwLimit: 700, stationName: '常州站10', id: 'czs10'},
      {rank: 71, kwLimit: 701, stationName: '常州站11', id: 'czs11'},
      {rank: 72, kwLimit: 702, stationName: '常州站12', id: 'czs12'},
      {rank: 73, kwLimit: 703, stationName: '常州站13', id: 'czs13'},
      {rank: 74, kwLimit: 704, stationName: '常州站14', id: 'czs14'},
      {rank: 75, kwLimit: 705, stationName: '常州站15', id: 'czs15'},
      {rank: 76, kwLimit: 706, stationName: '常州站16', id: 'czs16'},
      {rank: 77, kwLimit: 707, stationName: '常州站17', id: 'czs17'},
      {rank: 78, kwLimit: 708, stationName: '常州站18', id: 'czs18'},
      {rank: 79, kwLimit: 709, stationName: '常州站19', id: 'czs19'},
    ]
  })
}

async function requestData(params: Params): Promise<Result<Array<Data>>> {
  return fakeData();
  return request.run('/bigV1/stubNumInStatus', {
    method: 'GET', 
    params,
  })
}

function DeviceRecord() {
  const state = useContext(StateContext)!;
  const [status, setStatus] = useState<number>(DeviceStatus.充电中);
  const [params, setParams] = useState<Params>({
    pageNum: 1, pageSize: 100, status: DeviceStatus.充电中
  });
  // data 的变化不需要重新渲染
  const data = useRef<Array<Data>>([]);
  // 缓存数据，当接口调用失败时使用缓存数据
  const cacheData = useRef<Array<Data>>([]);
  // 请求函数，tab 切换时使用
    const req = useRequest(requestData, {manual: true, debounceWait: 100});
  // 请求函数，节流，数据不足时自动请求使用
  const req2 = useRequest(requestData, {manual: true, throttleWait: 1000 * 5});

  const start = (status: DeviceStatus) => {
    const dpr = window.devicePixelRatio;
    const list = document.getElementById('deviceRecordsList') as HTMLCanvasElement;
    if (!list) return;
    list.width = dpr * 327..toScale();
    list.height = dpr * 196..toScale();
    const ctx = list?.getContext('2d')!;
    ctx.scale(dpr, dpr);

    function draw() {
      function fittingString(str: string, maxWidth: number) {
        let strWidth = ctx.measureText(str).width;
        const ellipsis = '...';
        const ellipsisWidth = ctx.measureText(ellipsis).width;
        if (strWidth <= maxWidth || maxWidth <= ellipsisWidth) {
          return str;
        } else {
          var len = str.length;
          while (strWidth >= maxWidth - ellipsisWidth && len-- > 0) {
            str = str.slice(0, len);
            strWidth = ctx.measureText(str).width;
          }
          return str + ellipsis;
        }
      }
      function drawItem(y: number, data: Data) {
        // 清空画布
        ctx.clearRect(0, y, 327..toScale(), 196..toScale());
        // 矩形容器
        ctx.fillStyle = 'rgba(0, 128, 254, 0.05)';
        ctx.fillRect(0, y, 327..toScale(), 36..toScale());
        // 左上三角形
        ctx.fillStyle = 'rgba(0, 128, 254, 1)';
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(3..toScale(), y);
        ctx.lineTo(0, y + 3..toScale());
        ctx.fill();
        // 绘制文案
        ctx.fillStyle = 'rgba(255, 255, 255, 1)';
        ctx.font = `400 ${14..toScale()}px AlibabaPuHuiTi`;
        ctx.direction = 'ltr';
        ctx.fillText(fittingString(data.stationName, 126..toScale()), 10..toScale(), y + 23..toScale());
        ctx.fillText(fittingString(data.id, 80..toScale()), 146..toScale(), y + 23..toScale());
        if (status === DeviceStatus.充电中) {
          ctx.fillText(fittingString(`${data.rank}%`, 36), 231..toScale(), y + 23..toScale());
          ctx.direction = 'rtl';
          ctx.fillText(fittingString(`${data.kwLimit}kW`, 45), 317..toScale(), y + 23..toScale());
        }
      }

      for (let i = 0; i < 7; i++) {
        // const record = data.current[i] || cacheData.current[i];
        const record = data.current[i+recordIndex];
        if (!record) {
          console.log(data.current)
          console.log(cacheData.current)
        }
        drawItem(i * 40..toScale() - offsetCount, record);
      }

      offsetCount += 0.4.toScale();
      if (offsetCount >= 40..toScale()) {
        offsetCount = 0;
        // data.current.shift();
        if (recordIndex === data.current.length - 7) {
          recordIndex = 0;
        } else {
          recordIndex++;
        }
        // 小于 12 条数据时获取下一页的数据
        // if (data.current.length < 12) {
        //   getData(nextParams);
        // }
      }

      flag = requestAnimationFrame(draw);
    }

    draw();
  }

  const getData = (params: Params, reset?: boolean) => {
    const newParams = {...params};
    function cb(res: Result<Array<Data>>) {
      if (res.code === SUCCESS_CODE) {
        if (!res.data || res.data.length < 1) return;
        // 当前页 = 最大页数时，重新获取第一页的数据
        const maxPage = Math.ceil(res.totalCount / res.pageLength);
        if (maxPage === res.currentPageNo) {
          newParams.pageNum = 1;
        } else {
          newParams.pageNum += 1;
        }
        setParams(newParams);
        nextParams = newParams;
        if (maxPage === 1 && res.data.length > 0) {
          while (res.data.length < 200) {
            res.data = res.data.concat(res.data);
          }
          res.data = [res.data[0],res.data[1],res.data[2],res.data[3],res.data[4],res.data[5],res.data[7]].concat(res.data);
        }
        if (reset) {
          offsetCount = 0;
          recordIndex = 0;
          data.current = res.data;
        } else {
          data.current = data.current.concat(res.data);
        }
        // cacheData.current = JSON.parse(JSON.stringify(data.current));
        if (res.data.length > 0) {
          if (flag !== null) {
            cancelAnimationFrame(flag);
            flag = null;
          }
          start(params.status);
        }
      }
    }
    if (reset) {
      req2.cancel();
      req.runAsync(params).then(cb);
    } else {
      req2.runAsync(params).then(cb);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const nextStatus = getNextTab(status);
      setStatus(nextStatus);
    }, 1000 * 60)

    return () => {
      // if (flag !== null) {
      //   offsetCount = 0;
      //   cancelAnimationFrame(flag);
      //   flag = null;
      // }
      clearTimeout(timer);
    }
  }, [status]);

  useEffect(() => {
    if (!state.stationId) return;
    const newParams: Params = {
      ...params,
      pageNum: 1,
      status,
      stationId: state.stationId
    }
    setParams(newParams)
    getData(newParams, true);
  }, [status, state.stationId, state.headerTabKey]);

  return (
    <div className="deviceRecords" style={{marginTop: 60..toScale2()}}>
      <TabButtons<number>
        btns={[
          {label: '充电中', value: DeviceStatus.充电中},
          {label: '故障', value: DeviceStatus.故障},
          {label: '空闲', value: DeviceStatus.空闲},
          {label: '占用', value: DeviceStatus.占用},
          {label: '离线', value: DeviceStatus.离线},
          {label: '其他', value: DeviceStatus.其他}
        ]}
        minWidth={58}
        activeKey={status}
        onChange={setStatus}
      />
      <div className="body">
        <div 
          className="device-status-bg" 
          id={`device-status-bg-${DeviceStatus.充电中}`} 
          style={{
            opacity: status === DeviceStatus.充电中 ? 1 : 0,
            backgroundImage: `url(/img/charging-bg.png)`
          }} 
        />
        <div 
          className="device-status-bg" 
          id={`device-status-bg-${DeviceStatus.故障}`} 
          style={{
            opacity: status === DeviceStatus.故障 ? 1 : 0,
            backgroundImage: `url(/img/warning-bg.png)`
          }} 
        />
        <div 
          className="device-status-bg" 
          id={`device-status-bg-${DeviceStatus.空闲}`} 
          style={{
            opacity: status === DeviceStatus.空闲 ? 1 : 0,
            backgroundImage: `url(/img/free-bg.png)`
          }} 
        />
        <div 
          className="device-status-bg" 
          id={`device-status-bg-${DeviceStatus.占用}`} 
          style={{
            opacity: status === DeviceStatus.占用 ? 1 : 0,
            backgroundImage: `url(/img/using-bg.png)`
          }} 
        />
        <div 
          className="device-status-bg" 
          id={`device-status-bg-${DeviceStatus.离线}`} 
          style={{
            opacity: status === DeviceStatus.离线 ? 1 : 0,
            backgroundImage: `url(/img/outline-bg.png)`
          }} 
        />
        <div 
          className="device-status-bg" 
          id={`device-status-bg-${DeviceStatus.其他}`} 
          style={{
            opacity: status === DeviceStatus.其他 ? 1 : 0,
            backgroundImage: `url(/img/other-bg.png)`
          }} 
        />
        <div></div>
        <canvas className="list" id="deviceRecordsList">
        </canvas>
      </div>
    </div>
  )
}

export default DeviceRecord;
