import Card from "@/components/Card";
import TabButtons from "@/components/TabButtons";
import Alert from '@/components/Alert';
import Label from "@/components/Label";
import request from "@/request";
import { FAKE_DATA, SUCCESS_CODE } from "@/Constants";
import { StateContext } from "@/StateContext";
import { useRequest } from "ahooks";
import * as echarts from "echarts";
import { useContext, useState, useEffect } from "react";
import { generateYAxis, getMaxValue, getPie3D, play3DPieAnimation } from "@/utils";

enum Type {
  充电频次分布 = 1,
  功率选择分布 = 2,
  充电时段分布 = 3,
}

type Data = Array<{
  /**
   * 数值
   */
  num: number;
  /**
   * 占比
   */
  rank: number;
  /**
   * 标题
   */
  title: string;
}>

const Colors = [
  'rgba(45, 155, 252, 1)',
  'rgba(108, 208, 106, 1)',
  'rgba(255, 222, 124, 1)',
  'rgba(255, 93, 93, 1)',
  'rgba(112, 94, 249, 1)',
]

async function fakeData() {
  return Promise.resolve({
    ...FAKE_DATA,
    data: new Array(5).fill(1).map((_,i) => ({num: Math.floor(Math.random() * 10000 + 1000), rank: parseInt((Math.random() * 20 + 10).toFixed(2)), title: `FakeTitle_${i}`}))
  })
}

async function requestData(stationId: string, type: Type): Promise<Result<Data>> {
  return fakeData();
  return request.run('/bigV1/ChargeBehaveNum', {
    method: 'GET', 
    params: {
      stationId,
      type,
    }
  })
}

function drawPie(chart:echarts.ECharts, data: Data) {
  const option = getPie3D([{
    value: data[0]?.rank,
    itemStyle: {
      color: Colors[0],
    }
  }, {
    value: data[1]?.rank,
    itemStyle: {
      color: Colors[1],
    }
  }, {
    value: data[2]?.rank,
    itemStyle: {
      color: Colors[2],
    }
  }, {
    value: data[3]?.rank,
    itemStyle: {
      color: Colors[3],
    }
  }, {
    value: data[4]?.rank,
    itemStyle: {
      color: Colors[4],
    }
  }], 0.7);

  chart.setOption(option);
  play3DPieAnimation(chart);
}

function drawBar(chart: echarts.ECharts, data: Data) {
  const x = data.map(it => it.title);
  const y = data.map(it => it.num);
  const maxValue = getMaxValue(y);
  const option = {
    xAxis: {
      type: 'category',
      data: x
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: 'rgba(255,255,255,0.1)',
        }
      },
      axisLabel: {
        show: false,
      },
      max: maxValue, 
      interval: maxValue / 4,
    },
    grid: {
      top: 10..toScale2(),
      left: 40..toScale(), 
      right: 20..toScale(),
      bottom: 20..toScale2(),
    },
    animation: true,
    series: [
      {
        data: y,
        type: 'custom',
        renderItem: (params: any, api: any) => {
          //这里获取到的是柱状图顶部的坐标
          const topAxis = api.coord([api.value(0), api.value(1)]);
          //这里获取的是柱状图底部的坐标
          const bottomAxis = api.coord([api.value(0), 0]);
          return {
            type: 'group',
            children: [
              {
                type: 'path',
                style: {
                  fill: 'rgba(99, 182, 255, 1)',
                },
                shape: {
                  d: `
                    M${topAxis[0] - 10..toScale()}, ${topAxis[1]}
                    L${topAxis[0]}, ${topAxis[1] - 6..toScale()}
                    L${topAxis[0] + 10..toScale()}, ${topAxis[1]}
                    L${topAxis[0]}, ${topAxis[1] + 6..toScale()}
                    Z
                  `
                },
                enterFrom: {
                    style: { opacity: 0 },
                    y: 100..toScale() - topAxis[1]
                }
              },
              {
                type: 'path',
                style: {
                  fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(8, 130, 222, 1)' },
                    { offset: 1, color: 'rgba(1, 53, 227, 0)' }
                  ]),
                },
                shape: {
                  d: `
                    M${topAxis[0] - 10..toScale()}, ${topAxis[1]}
                    L${topAxis[0]}, ${topAxis[1] + 6..toScale()}
                    L${bottomAxis[0]}, ${bottomAxis[1]}
                    L${bottomAxis[0] - 10..toScale()}, ${bottomAxis[1]}
                    Z
                  `
                },
                enterFrom: {
                    style: { opacity: 0 },
                    y: 100..toScale() - topAxis[1]
                }
              },
              {
                type: 'path',
                style: {
                  fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(21, 154, 255, 1)' },
                    { offset: 1, color: 'rgba(0, 58, 255, 0)' }
                  ]),
                },
                shape: {
                  d: `
                    M${topAxis[0] + 10..toScale()}, ${topAxis[1]}
                    L${topAxis[0]}, ${topAxis[1] + 6..toScale()}
                    L${bottomAxis[0]}, ${bottomAxis[1]}
                    L${bottomAxis[0] + 10..toScale()}, ${bottomAxis[1]}
                    Z
                  `
                },
                enterFrom: {
                    style: { opacity: 0 },
                    y: 100..toScale() - topAxis[1]
                }
              },
            ],
          };
        }
      }
    ]
  };
  chart.setOption(option);
  generateYAxis(chart, maxValue);
}

function getTitle(type: Type) {
  switch (type) {
    case Type.充电频次分布:
      return ['1天', '2-3天', '4-7天', '8-15天', '15天以上'];
    case Type.功率选择分布:
      return ['1-15kW', '15-30kW', '30-45kW', '45-60kW', '60+kW'];
    default: return ['0-4时', '5-9时', '10-14时', '15-19时', '20-23时'];
  }
}

function ChargeAnalysis() {
  const state = useContext(StateContext);
  const [type, setType] = useState<Type>(Type.充电频次分布);
  const [charts, setCharts] = useState<Record<string, echarts.ECharts>>();
  const { data, mutate } = useRequest(() => requestData(state?.stationId!, type), {
    ready: !!charts && !!state?.stationId,
    refreshDeps: [type, state?.stationId, state?.headerTabKey],
    debounceWait: 100,
    onSuccess: (res) => {
      if (res.code === SUCCESS_CODE) {
        const titles = getTitle(type);
        const defaultData = [
          res.data[0] || {title: titles[0], num: 0, rank: 0},
          res.data[1] || {title: titles[1], num: 0, rank: 0},
          res.data[2] || {title: titles[2], num: 0, rank: 0},
          res.data[3] || {title: titles[3], num: 0, rank: 0},
          res.data[4] || {title: titles[4], num: 0, rank: 0},
        ]
        mutate({...res, data: defaultData});
        drawPie(charts!.pie, defaultData);
        drawBar(charts!.bar, defaultData);
      }
    }
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      const nextType = type === Type.充电时段分布 ? Type.充电频次分布 : type + 1;
      setType(nextType);
    }, 1000 * 60)

    return () => {
      clearTimeout(timer);
    }
  }, [type]);

  useEffect(() => {
    if (charts) return;
    // 0 为默认值，等于 0 意味着还未获取到当前的屏幕宽度
    if (state?.width === 0) return;
    const chartDom1 = document.getElementById('chargeTypeChart');
    if (!chartDom1) return;
    const chartDom2 = document.getElementById('chargeTypeBar');
    setCharts({
      pie: echarts.init(chartDom1),
      bar: echarts.init(chartDom2),
    });
  }, [state?.width]);
  
  return (
    <Card title='近90天用户充电行为分析' style={{marginTop: 68..toScale()}} bodyStyle={{position: 'relative'}}>
      <TabButtons<Type>
        btns={[
          {label: '充电频次分布', value: Type.充电频次分布},
          {label: '功率选择分布', value: Type.功率选择分布},
          {label: '充电时段分布', value: Type.充电时段分布}
        ]}
        style={{marginTop: 16..toScale(), marginBottom: 10..toScale(), position: 'relative', zIndex: 9}}
        activeKey={type}
        onChange={setType}
      />
      <div className='chargeTypePie' style={{marginTop: 20..toScale2()}}>
        <div id='chargeTypeChart'></div>
        <Alert
          color={Colors[0]}
          data={{
            cannel: data?.data[0]?.title || '1天',
            percent: data?.data[0]?.rank || 0,
            total: `${data?.data[0]?.num || 0}`,
            suffix: '次'
          }}
          style={{position: 'absolute', top: 0..toScale()}}
        />
        <Alert
          color={Colors[1]}
          data={{
            cannel: data?.data[1]?.title || '2-3天',
            percent: data?.data[1]?.rank || 0,
            total: `${data?.data[1]?.num || 0}`,
            suffix: '次'
          }}
          style={{position: 'absolute', top: 74..toScale()}}
        />
        <div className='row'>
          <Alert
            color={Colors[2]}
            data={{
              cannel: data?.data[2]?.title || '4-7天',
              percent: data?.data[2]?.rank || 0,
              total: `${data?.data[2]?.num || 0}`,
              suffix: '次'
            }}
          />
          <Alert
            color={Colors[3]}
            data={{
              cannel: data?.data[3]?.title || '8-15天',
              percent: data?.data[3]?.rank || 0,
              total: `${data?.data[3]?.num || 0}`,
              suffix: '次'
            }}
          />
          <Alert
            color={Colors[4]}
            data={{
              cannel: data?.data[4]?.title || '15天以上',
              percent: data?.data[4]?.rank || 0,
              total: `${data?.data[4]?.num || 0}`,
              suffix: '次'
            }}
          />
        </div>
      </div>
      <div className="chargeTypeBarArea" style={{marginTop: 60..toScale2()}}>
        <div className='label-row'>
          <Label>
            <span>单位: 次</span>
          </Label>
        </div>
        <div id="chargeTypeBar"></div>
      </div>
    </Card>
  )
}

export default ChargeAnalysis;
