import Counter from '@/components/Counter';
import './index.less';

interface BAlertProps extends React.PropsWithChildren {
  label: string;
  start: number;
  end: number;
}

const Alert: React.FC<BAlertProps> = (props) => {
  return (
    <div className='b-alert'>
      <div className='b-label'>
        {props.label}
      </div>
      <div className='b-value'>
        <Counter
          start={props.start}
          end={props.end}
        />
      </div>
    </div>
  )
}

export default Alert;
