import { Reducer, useEffect, useReducer, useState } from 'react';
import './App.less';
import Header from './components/Header';
import { StateContext, DispatchContext, initialState, stateReducer } from './StateContext';
import { MIN_WIDTH, GLOBAL_ASPECT_RATIO, HEADER_TAB_KEY } from './Constants';
import Container from './components/Container';
import LoadingAsset from './components/LoadingAsset';

// 需要预加载的资源，全部加载完毕后再进行渲染
const assetList = [
  '/img/bg1.png',
  '/img/bg2.png',
  '/img/card-title-bg.png',
  '/img/card-title-icon.png',
  '/img/seconds.png',
  '/img/map-bg.png',
  '/img/sort1.png',
  '/img/sort2.png',
  '/img/sort3.png',
  '/img/award1.png',
  '/img/award2.png',
  '/img/award3.png',
  '/img/top-bg.png',
  '/img/tab-btn-split.png',
  '/img/tab-btn-bg-active.png',
  '/img/tab-btn-outer-left-active.png',
  '/img/tab-btn-inner-left-active.png',
  '/img/tab-btn-inner-right-active.png',
  '/img/tab-btn-outer-right-active.png',
  '/img/tab-btn-bottom-active.png',
  '/img/tab-btn-bg.png',
  '/img/tab-btn-outer-left.png',
  '/img/tab-btn-inner-left.png',
  '/img/tab-btn-inner-right.png',
  '/img/tab-btn-outer-right.png',
  '/img/tab-btn-bottom.png',
  '/img/pie-inset-bg.png',
  '/img/charging-bg.png',
  '/img/warning-bg.png',
  '/img/free-bg.png',
  '/img/using-bg.png',
  '/img/outline-bg.png',
  '/img/other-bg.png',
  '/img/device-bg.png',
  '/img/free.png',
  '/img/charging.png',
  '/img/other.png',
  '/img/warning.png',
  '/img/using.png',
  '/img/outline.png',
  '/img/thunder3.png',
  '/img/thunder2.png',
  '/img/thunder.png',
  '/img/kw.png',
  '/img/map-bg.png',
  '/img/position-icon.png',
  '/img/power-bg.png',
  '/img/power-inset-bg.png',
  '/img/pie-inset.png',
  '/img/select-bg.png',
  '/img/select-icon.png',
  '/img/select-check.png',
  '/img/empty.png',
];

function App() {
  const [loadingVisible, setLoadingVisible] = useState(true);
  const [process, setProcess] = useState(0);
  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    stateReducer,
    initialState
  );

  const loadAssets = () => {
    assetList.forEach((url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        setProcess((process) => process + 1);
      }
    })
  }

  useEffect(() => {
    if (process >= assetList.length) {
      /**
       * 资源加载完成后停留 3 秒展示页面
       * 因为一次性加载的资源太多，所以延时展示
       */
      setTimeout(() => {
        setLoadingVisible(false);
        dispatch({
          type: 'setStationId',
          payload: {
            stationId: '_global'
          }
        })
        dispatch({
          type: 'setTabKey',
          payload: HEADER_TAB_KEY.CHARGE_DATA,
        })
      }, 1000 * 3)
    }
  }, [process]);

  useEffect(() => {
    // 设置轮询
    const interval = setInterval(() => {
      const date = new Date();
      const hour = date.getHours();
      const minute = date.getMinutes();
      // 每天 00:59 和 01:01 时重置需要刷新的标识
      if (hour < 1 && minute === 59 || hour === 1 && minute === 1) {
        dispatch({
          type: 'setNeedRefresh',
          payload: {
            needRefreshFor0015: false,
          }
        });
      }
      // 每天 01:00 时需要刷新部分接口数据
      if (hour === 1 && minute < 1 && !state.needRefreshFor0100) {
        dispatch({
          type: 'setNeedRefresh',
          payload: {
            needRefreshFor0015: true,
          }
        });
      }
    }, 1000 * 50);

    return () => {
      clearInterval(interval);
    }
  }, [state.needRefreshFor0100]);

  useEffect(() => {
    const main = document.getElementById('main')!;
    // const width = Math.max(document.body.clientWidth, MIN_WIDTH);
    // const height = width / GLOBAL_ASPECT_RATIO;
    const width = 1920;
    const height = 1080;

    window.scale = width / MIN_WIDTH;
    window.scale2 = width / MIN_WIDTH * 1.125;
    main.style.setProperty('--width', `${width}px`);
    main.style.setProperty('--height', `${height}px`);
    main.style.setProperty('--scale', `${window.scale}px`);

    dispatch({
      type: 'setScreenSize',
      payload: {
        width,
        aspectRatio: width / height,
      }
    });

    // 加载图片资源
    loadAssets()
  }, []);

  const p = process / assetList.length * 100;

  return (
    <div id="main" className="App">
      <div className='bg1' style={{backgroundImage: 'url(/img/bg1.png)'}}>
        <div className='bg2' style={{backgroundImage: 'url(/img/bg2.png)'}}>
          <div className="main">
            <StateContext.Provider value={state}>
              <DispatchContext.Provider value={dispatch}>
                <Header />
                <Container />
                {
                  loadingVisible && <LoadingAsset process={p} />
                }
              </DispatchContext.Provider>
            </StateContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
