import { FAKE_DATA, SUCCESS_CODE } from "@/Constants";
import { StateContext } from "@/StateContext";
import Card from "@/components/Card";
import request from "@/request";
import { useRequest } from "ahooks";
import * as echarts from 'echarts';
import { useContext, useEffect, useState } from "react";
import TabButtons from "@/components/TabButtons";

enum Type {
  用户画像 = 1,
  车辆品牌 = 2,
  充电次数 = 3,
}

type Data = Array<{
  /** 类型占比 */
  rank: number;
  /** 类型名称 */
  title: string;
}>;

async function fakeData() {
  return Promise.resolve({
    ...FAKE_DATA,
    data: new Array(9).fill(1).map((_,i) => ({rank: parseFloat((Math.random() * 20 + 5).toFixed(2)), title: `FakeTitle_${i}`}))
  })
}

async function requestData(type: Type, stationId?: string): Promise<Result<Data>> {
  return fakeData();
  return request.run('/bigV1/chargeTypeAnalysis', {
    method: 'GET', 
    params: {
      type,
      stationId
    }
  })
}

function splitLabel(str: string) {
  if (str.length < 6) return str;
  return str.substring(0, 4) + '...';
}

function draw(chart: echarts.ECharts, data: Data) {
  const option = {
    legend: {
      orient: 'vertical',
      top: '13%',
      left: '35%',
      height: '70%',
      itemGap: 8..toScale(),
      itemWidth: 8..toScale(),
      itemHeight: 8..toScale(),
      formatter: (name: string) => {
        const str = name.split(',');
        return `{a|${str[0]}}${str[1]}`;
      },
      textStyle: {
        color: '#D0DEEE',
        fontSize: 12..toScale(),
        fontWeight: 400,
        rich: {
          a: {
            width: 80..toScale()
          },
        },
      },
    },
    title: {
      text: '用户画像',
      left: '8%',
      top: 'center',
      textStyle: {
        color: 'rgba(255,255,255,0.9)',
        fontSize: 14..toScale(),
      },
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['59%', '65%'],
        center: ['15%', '50%'],
        itemStyle: {
          borderRadius: 20,
        },
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: false,
          },
          scale: false,
        },
        labelLine: {
          show: false
        },
        data: data.map(it => ({value: it.rank, name: `${splitLabel(it.title)},${it.rank}%`}))
      }
    ]
  };

  chart.setOption(option);
}

function ChargeTypeAnalysis() {
  const state = useContext(StateContext);
  const [type, setType] = useState<Type>(Type.用户画像);
  const [chart, setChart] = useState<echarts.ECharts>();
  useRequest(() => requestData(type, state?.stationId), {
    ready: !!chart && state?.stationId === '_global',
    refreshDeps: [type, state?.stationId, state?.headerTabKey],
    debounceWait: 100,
    onSuccess: (res) => {
      if (res.code === SUCCESS_CODE) {
        draw(chart!, res.data);
      }
    }
  })

  useEffect(() => {
    // 0 为默认值，等于 0 意味着还未获取到当前的屏幕宽度
    if (state?.width === 0) return;
    if (state?.stationId === '_global') {
      const chartDom = document.getElementById('chargeAnalysisChart');
      setChart(echarts.init(chartDom));
    } else {
      chart?.dispose();
      setChart(undefined);
    }
  }, [type, state?.stationId, state?.width]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const nextType = type === Type.充电次数 ? Type.用户画像 : type + 1;
      setType(nextType);
    }, 1000 * 60)

    return () => {
      clearTimeout(timer);
    }
  }, [type]);

  return (
    <Card title='充电类型信息分析' style={{marginTop: 58..toScale()}}>
      <TabButtons<Type>
        btns={[
          {label: '用户画像', value: Type.用户画像},
          {label: '车辆品牌', value: Type.车辆品牌},
          {label: '充电次数', value: Type.充电次数}
        ]}
        activeKey={type}
        onChange={setType}
        style={{marginTop: 16..toScale(), marginBottom: 8..toScale()}}
      />
      {
        state?.stationId === '_global'
        ? (
          <div key='chargeAnalysisChart' id='chargeAnalysisChart'></div>
        )
        : (
          <div key='empty' className="empty">
            <img alt='' src='/img/empty.png' />
            <span>切换至全局模式查看充电类型信息分析数据</span>
          </div>
        )
      }
    </Card>
  )
}

export default ChargeTypeAnalysis;
