import Card from "@/components/Card";
import Select from "@/components/Select";
import DeviceRecord from "./DeviceRecord";
import TerminalPower from "./TerminalPower";
import DevicePercent from "./DevicePercent";
import DeviceStatus from "./DeviceStatus";
import PowerPercent from "./PowerPercent";
import Map from '@/components/ChargeTab/Map';
import DeviceData from "./DeviceData";
import DeviceAndPowerChange from "./DeviceAndPowerChange";
import { HEADER_TAB_KEY } from "@/Constants";

const OperationsTab: React.FC = () => {
  return (
    <div className="container">
      <div className='containerLeft'>
        <DeviceData />
        <PowerPercent />
        <DeviceAndPowerChange />
      </div>
      <div className='containerMid'>
        <Select />
        <div className='midValue'></div>
        <Map type={HEADER_TAB_KEY.OPERATIONS_DATA} />
        <DeviceStatus />
      </div>
      <div className='containerRight'>
        <Card title='实时设备状态占比' style={{marginTop: 20..toScale2()}}>
          <DevicePercent />
          <DeviceRecord />
        </Card>
        <TerminalPower />
      </div>
    </div>
  )
}

export default OperationsTab;
