import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.less';
import { StateContext, DispatchContext } from '@/StateContext';
import TabButton from './TabButton';
import { SECONDS_PER_FRAME } from '@/Constants';
import FlowLight, { FlowLightRef } from '../FlowLight';

interface Info {
  date: string;
  time: string;
  weekday: string;
}

function addZero(num: number) {
  return num < 10 ? `0${num}` : num;
}

function getInfo() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  const weekday = date.getDay();
  return {
    date: `${year}.${addZero(month)}.${addZero(day)}`,
    time: `${hour}:${addZero(minute)}:${addZero(second)}`,
    weekday: `星期${['日', '一', '二', '三', '四', '五', '六'][weekday]}`,
  }
}

const Header: React.FC = () => {
  const state = useContext(StateContext)!;
  const [info, setInfo] = useState<Info>(getInfo());
  const dispatch = useContext(DispatchContext)!;
  const flowLightRef = useRef<FlowLightRef>(null);

  const onTabChange = (key: State['headerTabKey']) => {
    if (state.headerTabKey !== key) {
      dispatch({
        type: 'setTabKey',
        payload: key,
      })
      dispatch({
        type: 'setStationId',
        payload: {
          stationId: '_global'
        }
      })
    }
  }

  useEffect(() => {
    flowLightRef.current?.rePlay();
  }, [state.headerTabKey]);

  useEffect(() => {
    const interval = setInterval(() => {
      setInfo(getInfo());
    }, 1000)

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, []);

  return (
    <div className='header' style={{backgroundImage: 'url(/img/top-bg.png)'}}>
      <FlowLight ref={flowLightRef} />
      <div className='title'>
        <span>内蒙古蒙马运营可视化监控平台</span>
      </div>
      <div className='tabs'>
        <TabButton active={state.headerTabKey === 'chargeData'} onClick={() => onTabChange('chargeData')}>充电数据</TabButton>
        <img className='split' src='/img/tab-btn-split.png' alt='' />
        <TabButton active={state.headerTabKey === 'operationsData'} onClick={() => onTabChange('operationsData')}>运维数据</TabButton>
        {/* <img className='split' src='/img/tab-btn-split.png' alt='' />
        <TabButton active={state.headerTabKey === 'energyData'} onClick={() => onTabChange('energyData')}>能源数据</TabButton> */}
      </div>
      <div className='localInfo'>
        <div className='time'>{info.time}</div>
        <div className='more'>
          {/* <span>杭州</span> */}
          {/* <img className='weatherIcon' src='/img/sun.png' alt='' /> */}
          {/* <span>23℃~30℃</span> */}
          {/* <img className='split' src='/img/split.png' alt='' /> */}
          <span>{info.date}</span>
          <span style={{fontWeight: 400}}>{info.weekday}</span>
        </div>
      </div>
    </div>
  )
}

export default Header;
