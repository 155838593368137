import Alert from "@/components/Alert2";
import { useContext, useEffect, useState } from "react";
import * as echarts from 'echarts';
import { useRequest } from "ahooks";
import Card from "@/components/Card";
import { StateContext } from "@/StateContext";
import request from "@/request";
import { SUCCESS_CODE, FAKE_DATA } from "@/Constants";

interface Data {
  /**
   * 实时功率，充电中的设备的实时上报的最新功率
   */
  realRate: number;
  /**
   * 额定功率
   */
  staticRate: number;
}

async function fakeData() {
  return Promise.resolve({
    ...FAKE_DATA,
    data: {
      realRate: 600,
      staticRate: 800
    }
  })
}

async function requestData(stationId?: string): Promise<Result<Data>> {
  return fakeData();
  return request.run('/bigV1/stubRate', {
    method: 'GET', 
    params: {
      stationId
    }
  })
}

function draw(chart: echarts.ECharts, data: Data) {
  const gaugeData = [
    {
      value: Number((data.realRate / (data.staticRate || 1) * 100).toFixed(1)),
      name: '充电中',
      title: {
        offsetCenter: ['0%', '0%']
      },
      detail: {
        valueAnimation: true,
        offsetCenter: ['0%', '0%']
      }
    },
  ];
  function getOption(color1: string, color2: string) {
    return {
      series: [
        {
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          pointer: {
            show: false
          },
          radius: '100%',
          progress: {
            show: true,
            overlap: false,
            roundCap: false,
            clip: false,
            itemStyle: {
              borderWidth: 1,
              borderColor: 'transparent',
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: color1 // 0% 处的颜色
                }, {
                    offset: 1, color: color2 // 100% 处的颜色
                }],
                global: false // 缺省为 false
              },
            }
          },
          axisLine: {
            lineStyle: {
              width: 19..toScale(),
              color: [
                [1, 'rgba(0, 128, 254, 0.10)']
              ],
            }
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
          },
          data: gaugeData,
          title: {
            show: false
          },
          detail: {
            formatter: '{a|{value}}{b|%}',
            rich: {
              a: {
                fontSize: 28..toScale(),
                fontWeight: 800,
                color: 'rgba(255, 255, 255, 0.9)',
                align: 'center',
                padding: [0, 0, 0, 0]
              },
              b: {
                fontSize: 14..toScale(),
                fontWeight: 800,
                color: 'rgba(255, 255, 255, 0.90)',
                align: 'center',
                padding: [7..toScale(), 0, 0, 0]
              }
            }
          }
        }
      ]
    }
  }

  chart!.setOption(getOption('rgba(152, 200, 255, 0.1)', 'rgba(23, 123, 238, 1)'));
}

function PowerPercent() {
  const [chart, setChart] = useState<echarts.ECharts>();
  const state = useContext(StateContext);
  const { data } = useRequest(() => requestData(state?.stationId), {
    ready: !!state?.stationId,
    refreshDeps: [state?.stationId, state?.headerTabKey],
    pollingInterval: 1000 * 60,
    onSuccess: (res) => {
      if (res.code === SUCCESS_CODE) {
        draw(chart!, res.data);
      }
    }
  })

  useEffect(() => {
    // 0 为默认值，等于 0 意味着还未获取到当前的屏幕宽度
    if (state?.width === 0 || !!chart) return;
    const chartDom = document.getElementById('powerChart');
    setChart(echarts.init(chartDom));
  }, [state?.width]);

  return (
    <Card title='实时功率占比' style={{marginTop: 94..toScale()}}>
      <div className="power">
        <div className="chart">
          <div id="powerChart"></div>
          <img className="power-bg" alt='' src='/img/power-bg.png' />
          <img className="power-inset-bg" alt='' src='/img/power-inset-bg.png' />
        </div>
        <div className="data">
          <div className="item">
            <Alert
              color='rgba(45, 155, 252, 1)'
              data={{
                label: '实时功率',
                total: data?.data?.realRate?.toLocaleString() || '0',
              }}
            />
          </div>
          <div className="item">
            <Alert
              color='rgba(45, 155, 252, 1)'
              data={{
                label: '定额功率',
                total: data?.data?.staticRate?.toLocaleString() || '0',
              }}
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default PowerPercent;
