import { FAKE_DATA, SUCCESS_CODE } from "@/Constants";
import { StateContext } from "@/StateContext";
import Card from "@/components/Card";
import request from "@/request";
import { useRequest } from "ahooks";
import * as echarts from 'echarts';
import { useContext, useEffect, useState } from "react";

interface Data {
  /**
   * 私家车占比-私家车占车型的比例
   */
  carTypePri: number;
  /**
   * 车队占比-车队占车型的比例
   */
  carTypeTeam: number;
  /**
   * 运营车占比-运营车占车型的比例
   */
  carTypeWork: number;
  /**
   * 今日实时充电次数(次)
   */
  realChargeNum: number;
  /**
   * 今日实时充电量(度)
   */
  realChargePower: number;
  /**
   * 历史累计充电量(度)
   */
  totalChargePower: number;
  /**
   * 今日充电数据-排名DTO
   */
  todayRanks: SortInfo[];
  /**
   * 累计充电数据-排名DTO
   */
  totalRanks: SortInfo[];
}

async function fakeData() {
  return Promise.resolve({
    ...FAKE_DATA,
    data: {
      carTypePri: parseFloat((Math.random() * 100).toFixed(2)),
      carTypeTeam: parseFloat((Math.random() * 100).toFixed(2)),
      carTypeWork: parseFloat((Math.random() * 100).toFixed(2)),
      realChargeNum: Math.floor(Math.random() * 1000 + 200),
      realChargePower: Math.random() * 10000 + 2000,
      totalChargePower: Math.random() * 100000 + 20000,
      todayRanks: new Array(5).fill(1).map((_,i)=>({rank: i+1,powerNum: parseFloat(((5-i)*10000*Math.random()).toFixed(2)), stationName: `内蒙古${i+1}号`})),
      totalRanks: new Array(5).fill(1).map((_,i)=>({rank: i+1,powerNum: parseFloat(((5-i)*100000*Math.random()).toFixed(2)), stationName: `内蒙古${i+1}号`})),
    }
  })
}

async function requestData(stationId?: string): Promise<Result<Data>> {
  return fakeData();
  return request.run('/bigV1/chargeCenterData', {
    method: 'GET', 
    params: {
      stationId,
      type: 1, // 查询车辆类型分布占比数据
    }
  })
}

function draw(charts: Record<string, echarts.ECharts>, data: Data) {
  function getOption(color1: string, color2: string, percent: number) {
    return {
      series: [
        {
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          pointer: {
            show: false
          },
          radius: '100%',
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 1,
              borderColor: 'transparent',
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: color1 // 0% 处的颜色
                }, {
                    offset: 1, color: color2 // 100% 处的颜色
                }],
                global: false // 缺省为 false
              },
            }
          },
          axisLine: {
            lineStyle: {
              width: 6..toScale(),
              color: [
                [1, 'transparent']
              ],
            }
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
          },
          data: [{
            value: percent,
            name: 'Perfect',
            title: {
              offsetCenter: ['0%', '0%']
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ['0%', '0%']
            }
          }],
          title: {
            show: false
          },
          detail: {
            fontSize: 16..toScale(),
            color: '#D0DEEE',
            formatter: '{value}%'
          }
        }
      ]
    }
  }

  charts.chart1.setOption(getOption('rgba(25, 120, 229, 1)', 'rgba(0, 213, 255, 1)', data.carTypeTeam));
  charts.chart2.setOption(getOption('rgba(9, 152, 94, 1)', 'rgba(120, 255, 183, 1)', data.carTypeWork));
  charts.chart3.setOption(getOption('rgba(226, 188, 7, 1)', 'rgba(255, 229, 106, 1)', data.carTypePri));
}

function CardTypePercent() {
  const state = useContext(StateContext);
  const [charts, setCharts] = useState<Record<string, echarts.ECharts>>();
  const req = useRequest(() => requestData(state?.stationId), {
    ready: !!charts && state?.stationId === '_global',
    refreshDeps: [state?.stationId, state?.headerTabKey],
    onSuccess: (res) => {
      if (res.code === SUCCESS_CODE) {
        draw(charts!, res.data);
      }
    }
  })

  useEffect(() => {
    if (state?.needRefreshFor0100) {
      req.run();
    }
  }, [state?.needRefreshFor0100]);

  useEffect(() => {
    // 0 为默认值，等于 0 意味着还未获取到当前的屏幕宽度
    if (state?.width === 0) return;
    if (state?.stationId === '_global') {
      const chartDom1 = document.getElementById('pieDom1');
      const chartDom2 = document.getElementById('pieDom2');
      const chartDom3 = document.getElementById('pieDom3');
    
      setCharts({
        chart1: echarts.init(chartDom1),
        chart2: echarts.init(chartDom2),
        chart3: echarts.init(chartDom3),
      })
    } else {
      charts?.chart1.dispose();
      charts?.chart2.dispose();
      charts?.chart3.dispose();
      setCharts(undefined);
    }
  }, [state?.stationId, state?.width]);

  return (
    <Card title='车辆类型分布占比' style={{marginTop: 58..toScale()}}>
      {
        state?.stationId === '_global'
        ? (
          <div key='carTypePie' className='carTypePie'>
            <div>
              <div className='pie-outer'>
                <div id='pieDom1' className='pieDom'></div>
              </div>
              <div className='pie-label'>车队</div>
            </div>
            <div>
              <div className='pie-outer'>
                <div id='pieDom2' className='pieDom'></div>
              </div>
              <div className='pie-label'>运营车</div>
            </div>
            <div>
              <div className='pie-outer'>
                <div id='pieDom3' className='pieDom'></div>
              </div>
              <div className='pie-label'>私家车</div>
            </div>
          </div>
        )
        : (
          <div key='carEmpty' className="carEmpty">
            <img alt='' src='/img/empty.png' />
            <span>切换至全局模式查看车辆类型分布占比数据</span>
          </div>
        )
      }
      
    </Card>
  )
}

export default CardTypePercent;
